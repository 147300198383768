import React from 'react';

const CircularProgressBar = ({ progress, size, strokeWidth, color }) => {
  // Calculate the radius and circumference of the circle
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg width={size} height={size} className="circular-progress-bar">
      <circle
        stroke="#e6e6e6" // Background circle color
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        stroke={color} // Foreground circle color (progress)
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        style={{
          transition: 'stroke-dashoffset 0.35s',
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        style={{ fontSize: '16px', fill: '#333' }}
      >
        {`${progress}%`}
      </text>
    </svg>
  );
};

export default CircularProgressBar;
