import React from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import SectionContent from "./reusebleContent/SectionContent";
import leaveData from "./sectionData/leaveData";

const LeaveAttendance = () => {
  return (
    <>
      <InnerPageBanner title="Leave & Attendance Management" />
      <div className="pageContentWrapper">
        {leaveData.map((item) => {
          return (
            <SectionContent
              key={item.id}
              id={item.id}
              sectionImage={item.section_image}
              sectionDesc={item.section_desc}
            />
          );
        })}
      </div>
    </>
  );
};

export default LeaveAttendance;
