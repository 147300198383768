import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Link, Tooltip } from "@mui/material";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { forgotPasswordApi, updatePasswordApi } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import Modelsel from "../shared/Model/Modelsel";
import PasswordField from "../shared/PasswordField";

const ForgetPassword = ({ openForgotPassword, setForgotPassword }) => {
  const [authCode, setAuthCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(60);
  const [disableResendOtp, setDisableResendOtp] = useState(false);
  const [showCounter, setShowCounter] = useState(false);

  var intervalId;

  let validationSchema = yup.object().shape({
    username: yup.string().trim().required(""),
    password:
      authCode &&
      yup
        .string()
        .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, "Password at least have 8 character containing one special character, Uppercase, Lowercase and Digit")
        .required("Password is required"),
    otp: authCode && yup.string().trim().required("").length(6, "OTP must be exactly 6 digits"),
    confirmNewPassword:
      authCode &&
      yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Confirm Password"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      otp: "",
      password: "",
      confirmNewPassword: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const uName = formik.values.username;
  const otp = formik.values.otp;

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger mb-2">{formik.errors[field]}</div>;
  };

  const resendOTP = async () => {
    setLoading(true);
    setShowCounter(false);
    await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${forgotPasswordApi}?username=${encodeURIComponent(uName)}`)
      .then((response) => {
        setLoading(false);
        setAuthCode(true);
        toast.success(<IntlMessages id={"message.changePassword.sendOtp.success"} />);
        setDisableResendOtp(true);
        setShowCounter(true);
      })
      .catch((errors) => {
        toast.error(errors.response.data.message);
        setLoading(false);
      });
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    if (!authCode) {
      setLoading(true);
      await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${forgotPasswordApi}?username=${encodeURIComponent(uName)}`)
        .then((response) => {
          setAuthCode(true);
          setDisableResendOtp(true);
          setShowCounter(true);
          toast.success(<IntlMessages id={"message.changePassword.sendOtp.success"} />);
        })
        .catch((errors) => {
          toast.error(errors.response.data.message);
          setLoading(false);
        });
      setLoading(false);
    } else {
      setLoading(true);
      await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${updatePasswordApi}?authCode=${encodeURIComponent(otp)}`, {
        username: values.username.trim(),
        password: values.password.trim(),
      })
        .then((response) => {
          toast.success(<IntlMessages id={"button.text.passwordChangedSuccess.success"} />);
          setForgotPassword(false);
        })
        .catch((errors) => {
          toast.error(errors.response.data.message);
          setLoading(false);
        });
      setLoading(false);
    }
  };

  let count = 60;
  function startCounter() {
    intervalId = setInterval(() => {
      if (count == 1) {
        setDisableResendOtp(false);
        setShowCounter(false);
        setCounter(60);
        clearInterval(intervalId);
      }
      if (authCode) {
        setCounter((p) => p - 1);
        count--;
        //console.log(count);
      }
    }, 1000);
  }

  useEffect(() => {
    if (showCounter) {
      startCounter();
    }
  }, [showCounter]);

  return (
    <>
      <Modelsel
        open={openForgotPassword}
        heading={<IntlMessages id="send.otp.message" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="medium"
              onClick={() => setForgotPassword(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setForgotPassword(false)}
        maxWidth="md"
        footer={<span className="DisplayNone"></span>}>
        <Form
          className="mdForm forgotPassword"
          onSubmit={formik.handleSubmit}>
          <Row className="mb-2 mt-4">
            <Col
              xs={5}
              className="labelName">
              <Label className="fontSize requiredField">
                <IntlMessages id="forget.password.userName" />
              </Label>
            </Col>
            <Col
              xs={7}
              className="removeOutline">
              <FormGroup>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  onChange={formik.handleChange}
                  value={formik?.values?.username.trim()}
                  disabled={authCode ? true : false}
                  className="input-field-css"
                  invalid={formik.touched.username && !formik.values.username}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("username")}
              </FormGroup>
            </Col>
          </Row>
          {authCode && (
            <>
              <Row className="mb-2">
                <Col
                  xs={5}
                  className="labelName">
                  <Label className="fontSize requiredField">
                    <IntlMessages id="forget.password.otp" />
                  </Label>
                </Col>
                <Col
                  xs={7}
                  className="removeOutline">
                  <FormGroup>
                    <Input
                      type="number"
                      name="otp"
                      id="otp"
                      onChange={formik.handleChange}
                      value={formik.values.otp}
                      floatingLabelText="otp"
                      className="input-field-css"
                      invalid={formik.touched.otp && !formik.values.otp}
                      onBlur={formik.handleBlur}
                      placeholder="______"
                    />
                    {renderErrorMessage("otp")}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col></Col>
                <Col xs={7}>
                  <div className="counter-area-forgotPass">
                    {showCounter && (
                      <>
                        <span>Time to resend OTP : </span>

                        <div>
                          <span className="counter-span-forgotPass">{counter}</span>
                        </div>
                      </>
                    )}
                    {loading ? (
                      <Button
                        size="sm"
                        className="resendButton"
                        disabled>
                        <CircularProgress
                          color="secondary"
                          size={"15px"}
                        />
                      </Button>
                    ) : (
                      <Link
                        component="button"
                        disabled={loading || disableResendOtp}
                        onClick={() => resendOTP()}
                        className={disableResendOtp ? "disableResetButton" : "resendButton"}>
                        Resend OTP
                      </Link>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col
                  xs={5}
                  className="labelName">
                  <Label className="fontSize requiredField">
                    <IntlMessages id="forget.password.newPassword" />
                  </Label>
                </Col>
                <Col
                  xs={7}
                  className="removeOutline">
                  <FormGroup>
                    <PasswordField
                      name="password"
                      id="password"
                      onChange={formik.handleChange}
                      value={formik.values.password.trim()}
                      floatingLabelText="Enter your New Password"
                    />
                    {renderErrorMessage("password")}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col
                  xs={5}
                  className="labelName">
                  <Label className="fontSize requiredField">
                    <IntlMessages id="forget.password.confirmPassword" />
                  </Label>
                </Col>
                <Col
                  xs={7}
                  className="removeOutline">
                  <FormGroup>
                    <PasswordField
                      name="confirmNewPassword"
                      onChange={formik.handleChange}
                      value={formik.values.confirmNewPassword.trim()}
                      floatingLabelText="Enter your Confirm New Password"
                    />
                    {renderErrorMessage("confirmNewPassword")}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <Row className="Footer">
            <Col>
              <Button
                onClick={() => setForgotPassword(false)}
                className="buttonPosition"
                color="outline-secondary">
                <IntlMessages id="button.text.cancel" />
              </Button>
            </Col>
            <Col>
              <Button
                className="buttonPosition button-color"
                type="submit"
                disabled={loading}>
                {loading && (
                  <>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                    <IntlMessages id={!authCode ? `button.text.forgotPassword` : `button.text.updatePassword`} />
                  </>
                )}
                {!loading && <IntlMessages id={authCode ? `button.text.updatePassword` : `button.text.forgotPassword`} />}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modelsel>
    </>
  );
};

export default ForgetPassword;
