import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { myOrderApi } from "../../redux/ApiLists";
import { dateFormatter } from "../../util/Helper";
import Spinner from "../shared/spinner/Spinner";
import OrderDetailCard from "./OrderDetailCard";

const MyOrders = ({}) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(5);

  orders.map((OrderDetail) => {
    OrderDetail.orderdate = dateFormatter(OrderDetail.orderDate);
  });

  const getOrders = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosAdminConfig.get(`${myOrderApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`).then((result) => {
      [...result.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
      setOrders(result.resultList[0]);
      setCount(result.numberOfItems);
      setLoading(false);
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getOrders(page, rowsPerPage, "");
  }, [page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <section
        className="h-100 h-custom"
        style={{ backgroundColor: "#eee" }}>
        {loading ? (
          <div className="container py-3 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-lg-8 col-xl-6 text-center">
                <Spinner />
              </div>
            </div>
          </div>
        ) : (
          orders.map((order) => {
            return <OrderDetailCard order={order} />;
          })
        )}

        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-8 col-xl-6 text-center">
              {orders.length > 0 ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                  }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  backIconButtonProps={{
                    color: "secondary",
                  }}
                  nextIconButtonProps={{ color: "secondary" }}
                  showFirstButton={true}
                  showLastButton={true}
                  labelRowsPerPage={<span>Orders Per page:</span>}
                  sx={{
                    ".MuiTablePagination-toolbar": {
                      margin: "0 auto",
                    },
                  }}
                />
              ) : (
                <>No Orders Found</>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyOrders;
