import React from "react";
import FeaturedBox from "./FeaturedBox";
import websiteData from "../../websiteData/FEATURE_DATA.json";
import { Fade } from "react-awesome-reveal";

const Features = () => {
  return (
    <>
      <div
        className="container-fluid py-5 wow fadeInUp featureSection"
        id="featuresSection"
        data-wow-delay="0.1s">
        <div className="container">
          <div
            className="text-center mx-auto mb-5"
            style={{ maxWidth: "600px" }}>
            <h5 className="sectionTittle">Features</h5>
            <h4 className="sectionSubTittle">FEATURES PROVIDED BY OUR HRMS SYSTEM</h4>
          </div>
          <div className="row g-5">
            {websiteData.map((data, index) => {
              return (
                <>
                  <div className="col-lg-4 col-md-6">
                    <Fade bottom>
                      <FeaturedBox
                        data={data}
                        key={data.id}
                      />
                    </Fade>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
