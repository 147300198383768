import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Correct import

/**
 *
 * @param {React.ElementType} children required
 * @param {string} heading modal heading
 * @param {React.ElementType} footer modal footer controls
 * @param {Function} closeFn modal required toogle
 * @param {Boolean} open required
 *
 */

export default function RctModel(props) {
  const { children, footer, open, closePopUp, fullScreen = false, fullWidth = false, heading } = props;

  const theme = useTheme(); // Using the correct useTheme import
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      open={open}
      aria-labelledby="responsive-dialog-title">
      {closePopUp}
      {heading && <h4></h4>}
      <DialogContent className="modelSelfFooterSection">{children}</DialogContent>
      {footer && <DialogActions className="footerAction">{footer}</DialogActions>}
    </Dialog>
  );
}
