import React from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import SectionContent from "./reusebleContent/SectionContent";
import documentData from "./sectionData/documentData";

const DocumentManagement = () => {
  return (
    <>
      <InnerPageBanner title="Document Management" />
      <div className="pageContentWrapper">
      {documentData.map((item) => {
        return (
          <SectionContent
            key={item.id}
            id={item.id}
            sectionImage={item.section_image}
            sectionDesc={item.section_desc}
          />
        );
      })}
    </div>
    </>
  );
};

export default DocumentManagement;
