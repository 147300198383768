import employee1 from "../Images/employee/employee1.jpg";
import employee2 from "../Images/employee/employee2.jpg";
import employee3 from "../Images/employee/employee3.jpg";
import employee4 from "../Images/employee/employee4.jpg";
import employee5 from "../Images/employee/employee5.jpg";
import employee6 from "../Images/employee/employee6.jpg";
import employee7 from "../Images/employee/employee7.jpg";

export default [
  {
    id: 1,
    section_desc:
      "Employee Management System is a vital component of our HRMSHOST Software developed by Teksaar Innovations Pvt. Ltd. It serves as a comprehensive solution for efficiently organizing and maintaining employee-related information within an organization. With this system, companies can streamline their human resource operations and effectively manage their workforce.",
    section_image: employee1,
  },
  {
    id: 2,
    section_desc:
      "The main advantage of employee management is personal information. This feature allows HR personnel to store and update essential details about each employee, such as their full name, date of birth and gender etc.",
    section_image: employee2,
  },
  {
    id: 3,
    section_desc:
      "Another significant advantage of the Employee Management System is 'Demographic Information.' This component allows HR managers to gather and maintain demographic data, such as ethnicity, nationality, marital status, and languages spoken.",
    section_image: employee3,
  },
  {
    id: 4,
    section_desc:
      "The 'Contact and Address Details' enables HR departments to store employees' contact information, including phone numbers, email addresses, and residential addresses. Having this data readily accessible helps in seamless communication and ensures that important updates and announcements reach the employees in a timely manner.",
    section_image: employee4,
  },
  {
    id: 5,
    section_desc:
      "The 'Payment Information' handles the financial aspects of employee management. HR personnel can enter and manage salary-related details, including the employee's bank account information, tax deductions, and other payment-related particulars.",
    section_image: employee5,
  },
  {
    id: 6,
    section_desc:
      "Lastly, the 'Insurance Information' allows HR departments to maintain records of employees' insurance policies, including health insurance, life insurance, and other relevant coverage. It helps track policy details, premium payments, and beneficiaries, enabling HR personnel to effectively manage employee insurance benefits and promptly address any issues or claims that may arise.",
    section_image: employee6,
  },
  {
    id: 7,
    section_desc:
      "Overall, the Employee Management System within our HRMSHOST Software empowers organizations to centralize and efficiently handle various aspects of employee information. It simplifies HR processes, enhances data accuracy, and promotes effective workforce management, ultimately contributing to improved productivity and employee satisfaction.",
    section_image: employee7,
  },
];
