import enMessages from "./locales/en_US";
import tandc from "./locales/tandc";

const AppLocale = {
  en: {
    messages: {
      ...enMessages,
      ...tandc,
    },
    locale: "en-US",
  },
};

export default AppLocale;
