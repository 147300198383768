import { Link, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as yup from "yup";
import IntlMessages from "../../util/IntlMessages";
import Spinner from "../shared/spinner/Spinner";

const Step2 = ({ handleBack, company, setCompanyDetail, loading, setShowReg, counter, showCounter, disableResendOtp, resendOtp }) => {
  let validationSchema = yup.object().shape({
    otp: yup
      .string()
      .required("Please enter OTP")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const handleSubmit = async (values) => {
    setCompanyDetail((prev) => ({ ...prev, ...values }));
  };

  return (
    <div className="login-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-section">
              <div className="formTittle">
                <h3>
                  <IntlMessages id={`heading.company.verifyOtp`} />
                </h3>
              </div>
              <div className="login-inner-form">
                <Form
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center">
                      <IntlMessages id={`form.registerCompany.otpSent`} />
                    </Col>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center pb-4">
                      <strong>{company?.companyEmail}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center">
                      <FormGroup>
                        <TextField
                          error={formik.touched["otp"] && formik.errors["otp"]}
                          label={<IntlMessages id={`form.otp.text`} />}
                          id="otp"
                          name="otp"
                          value={formik.values.otp}
                          onChange={formik.handleChange}
                        />
                        {renderErrorMessage("otp")}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {loading && (
                      <Col>
                        <div className="text-center">
                          <Spinner />
                        </div>
                      </Col>
                    )}
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center">
                      <button
                        className={loading ? "button-disable" : "loginButton"}
                        type="submit"
                        disabled={loading ? true : false}>
                        <span>
                          <IntlMessages id={`form.otp.verifyOtp`} />
                        </span>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center">
                      <div>
                        Want to change email -{" "}
                        <span
                          className="linkText"
                          onClick={handleBack}>
                          Go back
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Row className="mb-2">
                  <div className="counterArea">
                    {showCounter && (
                      <>
                        <span>Time to resend OTP : </span>
                        <div>
                          <span className="counter-span">{counter}</span>
                        </div>
                      </>
                    )}

                    <Link
                      component="button"
                      disabled={loading || disableResendOtp}
                      onClick={() => resendOtp()}
                      className={loading || disableResendOtp ? "disableResetButton" : "resendButton"}>
                      Resend OTP
                    </Link>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
