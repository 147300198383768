import React from "react";
import { Fade } from "react-awesome-reveal";
import { Container } from "reactstrap";
import aboutUsImage from "../../assets/images/about-us-image.jpg";

export const About = () => {
  return (
    <Container
      id="aboutWraper"
      className="py-5 wow aboutSection"
      data-wow-delay="0.1s"
      fluid>
      <Container>
        <div className="row gx-5">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div className="aboutUsIMage">
              <img
                className="aboutImage"
                alt="We provide Human Resource Management Software"
                data-wow-delay="0.3s"
                src={aboutUsImage}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-lg-7 pt-2">
            <div className="mb-4">
              <h3 className="sectionTittle">About Us</h3>
              <h4>We provide Human Resource Management Software</h4>
            </div>
            <h5 className="text-body fst-italic mb-4">
              Our all in one software services includes :
              <div className="align-aboutus-points">
                <ul className="list-style-type">
                  <li>Employee Management</li>
                </ul>
                <ul className="list-style-type">
                  <li>Payroll Management</li>
                </ul>
                <ul className="list-style-type">
                  <li>Attendance & Leave Management</li>
                </ul>
                <ul className="list-style-type">
                  <li>Asset Management</li>
                </ul>
                <ul className="list-style-type">
                  <li>Document Management</li>
                </ul>
              </div>
            </h5>
            <p className="mb-4">
              The Application is built on robust architecture and is expendable to hold data and processing. Complete API-level access control is applied to make the application a highly secure HRMS
              system suitable for all sizes of organizations.
              <br />
              <br />
              We have separate application logins for the company admin and employees so that only features applicable to them are available.
              <br />
              <br />
              Application dashboard includes handy widgets like Holidays, Birthdays, Important URLs, Quick Documents, Company Events, current counts for employees, assets, users, projects, etc.
              <br />
              <br />
              Our cloud HRMS solution is truly a host for your HR Admin and Employees.
              {/* <br />
                <br />
                Login for HR Admin https://portal.htmshost.com/ & Login for employee https://employee.htmshost.com/ */}
            </p>
          </div>
        </div>
      </Container>
    </Container>
  );
};
