import leave1 from "../Images/leave/leave1.jpg";
import leave2 from "../Images/leave/leave2.jpg";
import leave3 from "../Images/leave/leave3.jpg";
import leave4 from "../Images/leave/leave4.jpg";
import leave5 from "../Images/leave/leave5.jpg";
import leave6 from "../Images/leave/leave6.jpg";

export default [
  {
    id: 1,
    section_desc:
      "Teksaar Innovations Pvt Ltd. has developed HRMSHOST Software, a comprehensive cloud-based human resource management solution that includes a robust Leave & Attendance feature. This feature is designed to streamline and automate the process of managing employee leave requests and tracking attendance, providing numerous benefits for organizations.",
    section_image: leave6,
  },
  {
    id: 2,
    section_desc:
      "One of the key functionalities of the Leave & Attendance feature is 'Leave Application & Approval'. Employees can easily submit leave requests through the software, eliminating the need for manual paperwork. The system then automates the approval process, routing requests to the appropriate managers for review and decision-making. This feature simplifies leave management, reduces administrative burdens, and ensures a streamlined workflow for both employees and HR personnel.",
    section_image: leave2,
  },
  {
    id: 3,
    section_desc:
      "HRMSHOST's Leave & Attendance feature also provides accurate and up-to-date information on 'Leave Balances'. Employees can view their available leave balances in real-time, giving them greater visibility and control over their time off. Likewise, HR managers can access comprehensive reports on leave balances, facilitating efficient workforce planning and ensuring adequate staffing levels at all times.",
    section_image: leave3,
  },
  {
    id: 4,
    section_desc:
      "Furthermore, the software includes a built-in calendar that accounts for 'Weekend and Holidays'. HRMSHOST automatically factors in weekends and public holidays when calculating leave durations, simplifying the process for both employees and administrators. ",
    section_image: leave4,
  },
  {
    id: 5,
    section_desc:
      "The Daily Attendance functionality of HRMSHOST's Leave & Attendance feature allows organizations to monitor employee attendance effortlessly. Employees can record their daily attendance through web portals.",
    section_image: leave5,
  },
  {
    id: 6,
    section_desc:
      "To maintain a comprehensive record of attendance data, HRMSHOST offers an 'Attendance Register'. This feature provides a centralized repository for storing and retrieving attendance records of all employees. ",
    section_image: leave1,
  },
];
