//h == heading, l == line, p == paragraph, b == bulletPoint

export default {
  "term.and.condition.l1": "Parts of this Agreement",
  "term.and.condition.p1":
    "This Agreement consists of the following terms and conditions (hereinafter the “General Terms”) and terms and conditions, if any, specific to use of individual Services (hereinafter the “Service Specific Terms”). In the event of a conflict between the General Terms and Service Specific Terms, the Service Specific Terms shall prevail.",
  "term.and.condition.l2": "Acceptance of the Agreement",
  "term.and.condition.p2":
    "You must be of legal age to enter into a binding agreement in order to accept the Agreement. If you do not agree to the General Terms, do not use any of our Services. If you agree to the General Terms and do not agree to any Service Specific Terms, do not use the corresponding Service. You can accept the Agreement by checking a checkbox or clicking on a button indicating your acceptance of the Agreement or by actually using the Services.",

  "term.and.condition.l3": "Description of service",
  "term.and.condition.p3":
    "We provide cloud software and applications as a service for businesses. You may use the Services for your personal and business use or for internal business purpose in the organization that you represent. You may connect to the Services using any Internet browser supported by the Services. You are responsible for obtaining access to the Internet and the equipment necessary to use the Services. You can create and edit content with your user account and if you choose to do so, you can publish and share such content.",

  "term.and.condition.h1": "1. Terms and conditions of Use:",
  "term.and.condition.h1.l1": "TEKSAAR INNOVATIONS PRIVATE LIMITED owns and operates the website ",
  "term.and.condition.h1.l2": "www.hrmshost.com",
  "term.and.condition.h1.p1":
    "By accessing the above websites and related pages, you are agreeing to accept and comply with the terms and conditions of use as stated below. If you do not agree with these Terms in their entirety, you may not use the Site.",
  "term.and.condition.h1.p2":
    "This Agreement may be amended at any time by us from time to time without specific notice to you. The latest Agreement will be posted on our website, and you should review this Agreement prior to using the Site. Your use of the site shall be deemed to be your agreement to abide by each of the terms set forth below, including any modifications that we may make.",

  "term.and.condition.h2": "2. TEKSAAR INNOVATIONS PRIVATE LIMITED offers the users a non-transferable, non-exclusive, non-revocable and non-transferable license to use our software as a Service.",

  "term.and.condition.h2": "2. Registration",
  "term.and.condition.h2.l1": "Upon registration with us, you agree to:",
  "term.and.condition.h2.b1": "Provide accurate, current, and complete information and maintain it updated at any point of time.",
  "term.and.condition.h1.b1": "Provide correct and complete credit information",
  "term.and.condition.h2.b2": "Maintain the security of your password and domain names",
  "term.and.condition.h2.b3": "Accept all risks of unauthorized access to your registration data, password, and domain names.",
  "term.and.condition.h2.b4": "Accept responsibility for all activity on your hrmshost.com account, and for all charges incurred thereon.",
  "term.and.condition.h2.b5": "Pay for the usage of the service as per the agreed rates including the applicable taxes.",

  "term.and.condition.h3": "3. Payments",
  "term.and.condition.h3.l1": "3.1. Free Trial",
  "term.and.condition.h3.p1":
    "If you register for a free trial of one or more Services, TEKSAAR INNOVATIONS will make the applicable Services available to you on a trial basis free of charge until the earlier of",
  "term.and.condition.h3.p1.l1": "(i) the end of the free trial period of the applicable Services (unless terminated earlier by you),",
  "term.and.condition.h3.p1.l2": "(ii) the start date of the paid subscription period for the applicable Services, or",
  "term.and.condition.h3.p1.l3":
    "(iii) termination by TEKSAAR INNOVATIONS in its sole discretion. Any data that you enter into the Services, and any customizations made to the Services during the free trial will be permanently lost unless you",
  "term.and.condition.h3.p1.l4": "(a) purchase the corresponding paid subscription plan for the account,",
  "term.and.condition.h3.p1.l5": "(b) purchase applicable Service upgrades, or",
  "term.and.condition.h3.p1.l6":
    "(c) export such data before the end of the trial period. Notwithstanding anything contained in this Section, Services are offered as-is during the free trial, without any warranty, covenant, support, or liability whatsoever, to the extent permitted by law.",

  "term.and.condition.h3.h2": "3.2  Subscription purchase or upgrade",
  "term.and.condition.h3.2": "TEKSAAR INNOVATIONS uses Invoice method of charging customers and collecting payment for the use of its services.",
  "term.and.condition.h3.2.p1":
    "The method applicable to you will be indicated in your order form upon sign up or in the sign-up page within the application. You may contact support@hrmshost.com for any information you may want on the invoicing and payments. In order to access and use the application, you are required to top up your account or pay the invoices raised from time to time. TEKSAAR INNOVATIONS may make changes in the way it charges and collects payments and the same shall be intimated to you in advance.",
  "term.and.condition.h3.3":
    "3.3 An active employee means an employee whose salary gets processed in the normal course and doesn't include employees for whom all exit formalities have been completed in the system. An employee whose salary has been put on hold or marked for exit and employee marked as “exclude from payroll” is also considered as an active employee.",
  "term.and.condition.h3.4":
    "3.4 For all customers, TEKSAAR INNOVATIONS supports payment through debit card, credit card, net banking through a payment gateway. For payment against an invoice, payment shall be made to TEKSAAR INNOVATIONS by Online transfer in favour of TEKSAAR INNOVATIONS PRIVATE LIMITED Bank account details –",
  "term.and.condition.h3.5": "3.5. The price at which you sign up is valid for 1 year and thereafter a hike of 10% is applied to this price.",
  "term.and.condition.h3.6":
    "3.6 If an account remains blocked for a period of 60 days or more, all the master, payroll, tax, and other data stored on our servers shall be forthwith deleted with a 30-day prior notice and we shall not be responsible for any consequences arising out of such deletion.",
  "term.and.condition.h3.7":
    "3.7 For all customers under the invoice method, prior to the end of the Subscription Term, TEKSAAR INNOVATIONS will send you notice of the then-current annual Subscription Fees for renewal. Any failure to pay the Subscription Fees in accordance with an Order Form or Renewal Notice will result in automatic revocation and termination of this Agreement and all rights and licenses granted hereunder.",
  "term.and.condition.h3.8":
    "3.8 Any billing disputes must be reported to TEKSAAR INNOVATIONS in writing within 60 days of the date of the invoice. All Subscription Fees are non-refundable once paid.",
  "term.and.condition.h3.9": "3.9 The FAQs on billing in the subscription page in the application forms part of this Terms of Service document.",

  "term.and.condition.h4": "4. Limitation of Liability",
  "term.and.condition.h4.p1":
    "The user to the software is presumed to be well conversant with payroll processing and all applicable legal compliance's. It shall be the user’s responsibility to cross check and confirm the accuracy of the reports, forms, and data. The software platform is provided on a “AS-IS,” “AS AVAILABLE,” with “ALL FAULTS”, and all WARRANTIES, EXPRESS or IMPLIED basis. TEKSAAR INNOVATIONS expressly disclaims all warranties, whether express or implied, including, but not limited to, the implied warranties of merchantability, freedom from viruses or other harmful components, fitness for a particular purpose and non-infringement. TEKSAAR INNOVATIONS makes no warranty that: (a) the platform/service/software will meet your requirements; (b) the site will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the site or any services offered through the site will be accurate or reliable.",
  "term.and.condition.h4.p2":
    "In no event shall TEKSAAR INNOVATIONS be liable for any direct, indirect, incidental, special or consequential damages, or damages for loss of profits, revenue, data or use or inability to use, incurred by you whether in an action in contract or tort, arising from your access to, or use of, the site, whether or not TEKSAAR INNOVATIONS has been advised of the possibility.",
  "term.and.condition.h4.p3":
    "No advice or information, whether oral or written, obtained by you from us through the site or otherwise shall create any warranty, representation or guarantee not expressly stated in this agreement.",

  // Support Service

  "support.and.service.h1": "5. Support",
  "support.and.service.h1.1":
    "1. HRMS HOST Support on all matters of usage. The website shall be updated with Help documentation/slide shows/short videos detailing various Support and Help files. HRMS HOST shall offer only remote support and not onsite support.",
  "support.and.service.h1.2": "2. HRMS HOST may access your account and data as necessary to identify or resolve technical problems and tickets or respond to complaints about the Services.",

  "support.and.service.h1.3":
    "If you desire not to allow access to your account for the support team, please send a mail of the instruction to support@hrmshost.com and in all such cases, the tickets would be responded to only by way of a screen share / remote desktop option.",

  "support.and.service.h1.3.l1": "3. Ticket Resolution:",
  "support.and.service.h1.3.1":
    "Information tickets: All tickets which seek clarifications on usage of HRMS HOST, features or steps to perform certain tasks in HRMS HOST, or seeking information on the payroll related activities shall be addressed within next business days based on availability of our support executives.",
  "support.and.service.h1.3.2":
    "Trouble tickets: All tickets in seeking help with getting certain activities done in HRMS HOST through support of HRMS HOST technical support team shall be addressed within next business days based on availability of our support executives.",
  "support.and.service.h1.3.3": "Any other tickets shall be addressed within 3 business days and is solely depends on availability of support executives.",
  "support.and.service.h1.4": "4. The working hours of HRMS HOST are between 11:00 AM and 6:00 PM IST Monday thru Friday except on Public Holidays.",

  // Cancellation and Refundable
  "cancellation.and.refundable.l1":
    "We need customer to start with one-month free trial of our application. This gives customer a full detail of how our application works and what all features we provide.",
  "cancellation.and.refundable.l2":
    "We consider payment are made only when customer is OK with our application and willing to pay for the service. Once payment is made customer can fully utilize our application till subscription end date.",
  "cancellation.and.refundable.l3":
    "We do not record any issue during payment as we use third party payment gateway. Please check all the conditions and required field values on the payment page before you make payment. Purchased subscription is not cancellable, non-refundable, and non-transferable.",
  "cancellation.and.refundable.l4":
    "Whether you use the service or do not use the service after subscription is bought. If any error occurs during payment and you find that payment is deducted from your account and subscription is not updated in our application as per payment made, then please contact our support team at support@hrmshost.com. We will try to get back to you asap.",
  "cancellation.and.refundable.l5": "Please visit our customer support link for more details",

  // Privacy Policy

  "privacy.and.policy.p1":
    "We ask for only the least amount of information necessary, gathering only what we believe is essential for doing business, or for the specific transaction. We let customers know the information we have on them and allow them to opt out of specific engagements.",
  "privacy.and.policy.p2":
    "The goal of this policy is to make explicit the information we gather, how we will use it, and how we will not. This part deals with how HRMS HOST handles data that you entrust to HRMS HOST when you use our products and services, or when you share any personal or confidential information with us while requesting customer support.",
  "privacy.and.policy.p3":
    "Account signup: When you sign up for an account to access one or more of our services, we ask for information like your name, contact number, email address, company name and country to complete the account signup process. You'll also be required to choose a unique username and a password for accessing the created account.",
  "privacy.and.policy.p4":
    "Interactions with HRMS HOST: We may record, analyses and use your interactions with us, including email, telephone, and chat conversations with our sales and customer support professionals, for improving our interactions with you and other customers.",
  "privacy.and.policy.h1": "Purpose for using information",
  "privacy.and.policy.h1.1": "In addition to the purposes mentioned above, Information collected by us may be used to :",
  "privacy.and.policy.h1.l1":
    "- Communicate with you (such as through email) about services that you have signed up for, changes to this Privacy Policy, changes to the Terms of Service, or important notices",
  "privacy.and.policy.h1.l2": "- Keep you posted on services, upcoming offers, promotions, and other information that we think will be of interest to you",
  "privacy.and.policy.h1.l3": "- Ask you to participate in surveys, or to solicit feedback on our products and services",
  "privacy.and.policy.h1.l4": "- Set up and maintain your account, and to do all other things required for providing our services",
  "privacy.and.policy.h1.l5": "- Understand how users use our products and services, to monitor and prevent problems, and to improve our products and services",
  "privacy.and.policy.h1.l6": "- Provide customer support, and to analyses and improve our interactions with customers",
  "privacy.and.policy.h1.l7":
    "- Detect and prevent fraudulent transactions and other illegal activities, to report spam, and to protect the rights and interests of HRMS HOST, HRMS HOST’s users, third parties and public.",
  "privacy.and.policy.h1.l8": "- Update, expand and analyses our records, identify new customers, and provide products and services that may be of interest to you",
  "privacy.and.policy.h1.l9": "- Monitor and improve marketing campaigns and make suggestions relevant to the user.",
  "privacy.and.policy.h1.l10": "- Prevent fraud or potentially illegal activities (including, without limitation, copyright infringement) on or through Our Website or Services,",
  "privacy.and.policy.h1.l11": "- Protect the safety of our other subscribers or Users",
  "privacy.and.policy.h1.2":
    "Payment processing: We do not record any issue during payment as we use third party payment gateway. Please check all the conditions and required field values on the payment page before you make payment.",
  "privacy.and.policy.h2": "Children's Privacy",
  "privacy.and.policy.h2.1":
    "Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.",
  "privacy.and.policy.h3": "Business Transfers",
  "privacy.and.policy.h3.1":
    "We do not intend to sell our business. However, in the unlikely event that we sell our business or get acquired or merged, we will ensure that the acquiring entity is legally bound to honour our commitments to you. We will notify you via email or through a prominent notice on our website of any change in ownership or in the uses of your personal information and service data. We will also notify you about any choices you may have regarding your personal information and service data.",
  "privacy.and.policy.h4": "Enforcement of our rights",
  "privacy.and.policy.h4.1":
    "We may disclose personal information and service data to a third party if we believe that such disclosure is necessary for preventing fraud, spam filtering, investigating any suspected illegal activity, enforcing our agreements or policies, or protecting the safety of our users.",
  "privacy.and.policy.h5": "Disclosures in compliance with legal obligations",
  "privacy.and.policy.h5.1":
    "We may be required by law to preserve or disclose your personal information and service data to comply with any applicable law, regulation, legal process, or governmental request, including to meet national security requirements.",
  "privacy.and.policy.h6": "Notification of changes",
  "privacy.and.policy.h6.1":
    "We may update the Privacy Policy at any time, upon notifying you through a service announcement or by sending an email to your primary email address. However, if you have not verified your email address, you may miss important notifications that we send through email. Your continued use after the effective date of changes to the Privacy Policy will be deemed to be your agreement to the modified Privacy Policy.",
  "privacy.and.policy.h7": "Questions regarding this policy",
  "privacy.and.policy.h7.1":
    "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@hrmshost.com. Thank you for your business and we look forward to serving you!",
};
