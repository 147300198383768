import React from "react";
import IntlMessages from "../../util/IntlMessages";

function CustomerSupport() {
  return (
    <>
      <>
        <div className="heading">
          <span>Customer Support</span>
        </div>
        <div className="card border-3 shadow-lg container-body mt-2 mb-4">
          <div className="card-body">
            <div style={{ fontFamily: "-moz-initial" }}>
              <p>
                <IntlMessages id="support.and.service.h1.1" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.2" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.3" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.3.l1" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.3.1" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.3.2" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.3.3" />
              </p>
              <p>
                <IntlMessages id="support.and.service.h1.4" />
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default CustomerSupport;
