import React from "react";
import { PopupWidget } from "react-calendly";

const ScheduleDemo = () => {
  return (
    <>
      <div className="App">
        <PopupWidget
          url={process.env.REACT_APP_CALENDLY_URL}
          rootElement={document.getElementById("root")}
          text="Schedule A Demo"
          textColor="#ffffff"
          color="#df6523"
        />
      </div>
    </>
  );
};

export default ScheduleDemo;
