import React from "react";

const CookiesComponent = ({}) => {
  return (
    <>
      {/* <CookieConsent location="bottom" cookieName="myAwesomeCookieName3" expires={999} overlay buttonStyle={{ backgroundColor: "#008080", color: "#fff", borderRadius: "3px", textAlign: "center" }}>
       This website uses cookies to enhance the user experience. //{" "}
      </CookieConsent> */}
    </>
  );
};
export default CookiesComponent;
