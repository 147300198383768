import React from "react";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import IntlMessages from "../../util/IntlMessages";
import { useState } from "react";

const OrderSuccessPopup = ({ openDialog, setOpenSuccessDialog }) => {
  const [loading, setLoading] = useState(false);
  const redirectToHome = () => {
    window.location.href = "/";
    setOpenSuccessDialog(false);
  };
  return (
    <>
      <ModelSelfFooter
        fullWidth={true}
        open={openDialog}
        heading={<IntlMessages id="form.text.plan.detail" />}
        closeFn={() => setOpenSuccessDialog(false)}>
        <>
          <div
            style={{ fontFamily: "-moz-initial" }}
            id="paySlipSection"
            className="content container-fluid paySlipSection">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col">
                    <span className="success-text-msg">
                      Thank you for buying the plan. You can see your&nbsp;
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/orders">
                        Order details
                      </Link>
                      &nbsp;.
                    </span>
                  </div>
                  <div className="">
                    <div
                      className="invoiceFooter"
                      style={{ textAlign: "center" }}>
                      <Button
                        onClick={redirectToHome}
                        className="buttonSize mt-4"
                        color="primary"
                        variant="contained"
                        disabled={loading ? true : false}>
                        {loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {loading && <span>Ok</span>}
                        {!loading && <span>Ok</span>}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </ModelSelfFooter>
    </>
  );
};
export default OrderSuccessPopup;
