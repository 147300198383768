import React from "react";
import IntlMessages from "../../util/IntlMessages";

function PrivacyPolicy() {
  return (
    <>
      <>
        <div className="heading">
          <span>Privacy Policy</span>
        </div>
        <div className="card border-3 shadow-lg container-body mt-2 mb-4">
          <div className="card-body">
            <div style={{ fontFamily: "-moz-initial" }}>
              <p>
                <IntlMessages id="privacy.and.policy.p1" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.p2" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.p3" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.p4" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h1" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.1" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l1" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l2" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l3" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l4" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l5" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l6" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l7" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l8" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l9" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l10" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.l11" />
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h1.2" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h2" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h2.1" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h3" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h3.1" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h4" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h4.1" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h5" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h5.1" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h6" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h6.1" />
              </p>
              <p>
                <span className="text-underline">
                  <IntlMessages id="privacy.and.policy.h7" />
                </span>
              </p>
              <p>
                <IntlMessages id="privacy.and.policy.h7.1" />
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default PrivacyPolicy;
