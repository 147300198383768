import React from "react";
import "./innerPage.css";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import SectionContent from "./reusebleContent/SectionContent";
import EmployeeData from "./sectionData/employeeData";

const EmployeeManagement = () => {
  return (
    <>
      <InnerPageBanner title="Employee Management" />
      <div className="pageContentWrapper">
        {EmployeeData.map((item) => {
          return (
            <SectionContent
              key={item.id}
              id={item.id}
              sectionImage={item.section_image}
              sectionDesc={item.section_desc}
            />
          );
        })}
      </div>
    </>
  );
};

export default EmployeeManagement;
