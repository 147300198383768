import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-MP3HWJNMJ8",
};
TagManager.initialize(tagManagerArgs);

// ReactDOM.render(<App />, document.getElementById("root")); // remove this after successfully run on latest react version
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
