// Employee Roles
export const EmployeeRoleApi = `employee-roles`;

// ********************************* HRMS Admin API's**************************

const erContext = `er`;

// Current plan
export const getcurrentPlan = `${erContext}/currentSubscription`;
export const getupcomingPlan = `${erContext}/upcomingSubscription`;

// Upgrade Company Plan
export const upgradeCompanyPlanApi = `${erContext}/company-subscription`;
export const myOrderApi = `${erContext}/orders`;
export const orderDetailList = `${erContext}/orders`;
// Company Detail Controller
const company = `company`;
export const companyDetailApi = `${company}`;

//  Public Api Controller
const publicApis = `public`;
export const createCompany = `${publicApis}/company`;
export const loginCompany = `${publicApis}/login`;
export const createCompanyFirstUser = `${publicApis}/user`;
export const getAllStateApi = `${publicApis}/all-state`;
export const logoutApi = `${publicApis}/logout`;

// Terms and Conditions
export const termsAndConditionsApi = `${publicApis}/termsAndConditions`;

// Subscription-plan-controller
export const adminSubscriptionsApi = `plan`;
export const getAllSubscriptionsPlan = `${publicApis}/plans`;

// Forgot Password
export const forgotPasswordApi = `${publicApis}/user/forgotPassword`;
export const updatePasswordApi = `${publicApis}/user/updatePassword`;
export const changePasswordApi = `${publicApis}/user/changePassword`;

// Keycloak Controller -----------------------------------------
const users = `er/users`;
export const usersApi = `${users}`;
export const getLoggedInCompanyDetailApi = `${users}/company`;

const payment = `er/payments`;
export const paymentApi = `${payment}/order`;
export const planUpgrade = "planUpgrade";

//tax controller
export const ProfessionalTaxAPI = "tax/calculate-pt";
