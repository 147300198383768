import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import offerRibbon from "../../assets/images/ribbon.png";
import { currencyConverterForPlanBoxes } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import CompanyRegistrationWrapper from "../companyRegistration/CompanyRegistrationWrapper";
import CreateOrderPopup from "../myOrder/CreateOrderPopup";
import OrderSuccessPopup from "../myOrder/OrderSuccessPopup";
import { getAccessToken } from "../auth/HandleCookies";
import DoneIcon from "@mui/icons-material/Done";

const PlanDetailBox = ({ durationType, plan, currentPlan, successDialog }) => {
  const [showReg, setShowReg] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [isUpgradeEvent, setUpgradEvent] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  let access_token = getAccessToken();
  const [selectedPlan, setSelectedPlan] = useState();

  const inputElement = useRef();
  let priceData;
  let planPrice;
  if (plan?.plan) {
    priceData = currencyConverterForPlanBoxes(plan?.plan?.netPrice).slice(1);
    planPrice = currencyConverterForPlanBoxes(plan?.plan?.planPrice).slice(1);
  }

  useEffect(() => {
    inputElement.current.classList.add("is-switched");
    inputElement.current.children[0].classList.add("is-selected");
    setTimeout(() => {
      inputElement.current.classList.remove("is-switched");
      inputElement.current.children[0].classList.remove("is-selected");
    }, 200);
  }, [durationType]);

  const onClickByNow = (planId) => {
    console.log("on click by now");
    setOpenInvoice(true);
  };
  const onClickUpgradeNow = (planId) => {
    setUpgradEvent(true);
    setOpenInvoice(true);
  };
  const canBuyPlan = (plan) => {
    if (plan.maxEmployee < currentPlan.maxEmployee) {
      toast.error(<IntlMessages id="plan.buy.error.message"></IntlMessages>);
      return false;
    }
    return true;
  };
  const onClickStartNow = (selectedPlanObj) => {
    setSelectedPlan(selectedPlanObj);
    setShowReg(true);
  };

  return (
    <>
      <Col className="planColumn">
        <ul
          className="pricing-wrapper"
          ref={inputElement}>
          <Fade bottom>
            <li className={`is-visible border planColumn ${plan?.plan?.planType}`}>
              <header className="pricing-header">
                {plan?.plan?.discountPercent > 0 ? (
                  <div className="offerRibbon">
                    <img
                      alt="Human Resource Management Software ribbon"
                      src={offerRibbon}
                    />
                    <span className="offerAmount">{plan?.plan?.discountPercent}% Off</span>
                  </div>
                ) : null}
                <h2>{plan?.plan?.planType}</h2>
                <span className="duration">({durationType})</span>
                <span className="planInfo">Up to : {plan?.plan?.maxEmployee} Employees</span>
                <div className="price">
                  <span className="currency">₹</span>
                  <span className="value">{priceData}</span>
                  {plan?.plan?.discountAmount > 0 ? (
                    <>
                      <span className="totalpriceSymbol">₹</span>
                      <span className="totalPlan">{planPrice}</span>
                    </>
                  ) : null}
                </div>
              </header>
              <div className="m-3">
                {!access_token ? (
                  <a
                    onClick={() => onClickStartNow(plan?.plan)}
                    className="buyNowButton"
                    target="_blank">
                    <IntlMessages id={"plan.startNow"} />
                  </a>
                ) : (
                  <a
                    onClick={() => canBuyPlan(plan?.plan) && onClickByNow(plan?.plan?.id)}
                    className={!!currentPlan ? "buyNowButton" : "buyNowButtonDisabled"}
                    target="_blank">
                    <IntlMessages id={"plan.buyNow"} />
                  </a>
                )}

                {plan?.plan && plan?.plan?.upgradable && (
                  <a
                    onClick={() => onClickUpgradeNow(plan?.plan?.id)}
                    className="buyNowButton">
                    <IntlMessages id={"plan.upgradeNow"} />
                  </a>
                )}
                {currentPlan?.planId == plan?.plan?.id && (
                  <div className="d-flex flex-row justify-content-center">
                    <span style={{ fontSize: "smaller", color: "brown" }}>{currentPlan.flagTrial ? "#Current Trial Plan" : "#Current Plan"} </span>
                  </div>
                )}
              </div>
              <div className="mt-3 text-center">
                <h3 className="otherResourcesTitle">Features</h3>
              </div>
              <div className="pricing-body">
                <ul className="planIncluded">
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Employee Management</span>
                    {/* <span className="colonSymbol">:</span>
                    <span className="includedNumber">{plan?.plan?.maxAsset}</span> */}
                  </li>
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Payroll Management</span>
                  </li>
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Leave Management </span>
                  </li>
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Location Based Attendance</span>
                  </li>
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Compensation & Taxes</span>
                  </li>
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Asset & Project</span>
                  </li>
                  <li className="includedItem">
                    <span className="colonSymbol">
                      <DoneIcon />
                    </span>
                    <span className="includedHeading">Document Management</span>
                  </li>
                </ul>
              </div>
            </li>
          </Fade>
        </ul>
      </Col>
      {showReg && selectedPlan && (
        <CompanyRegistrationWrapper
          plan={selectedPlan}
          setShowReg={setShowReg}
        />
      )}
      {openInvoice && (
        <CreateOrderPopup
          planId={plan?.plan?.id}
          openDialog={openInvoice}
          setOpenDialog={setOpenInvoice}
          showPayButton={true}
          isUpgradeEvent={isUpgradeEvent}
          setOpenSuccessDialog={setOpenSuccessDialog}
        />
      )}
      {openSuccessDialog && (
        <OrderSuccessPopup
          openDialog={openSuccessDialog}
          setOpenSuccessDialog={setOpenSuccessDialog}
        />
      )}
    </>
  );
};

export default PlanDetailBox;
