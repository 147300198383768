import { useEffect, useState } from "react";
import { FaUser, FaLaptopCode, FaProjectDiagram, FaFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingPlan } from "../../redux/actions";
import IntlMessages from "../../util/IntlMessages";
import Spinner from "../shared/spinner/Spinner";

const UpcomingSubscriptionCard = () => {
  const [upcomingPlan, setUpcomingPlan] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const upcomingPlanFromStore = useSelector((store) => {
    return store.companySubscriptionReducer.upcomingPlan;
  });
  useEffect(() => {
    if (upcomingPlanFromStore.data && Object.keys(upcomingPlanFromStore.data).length > 0 && !upcomingPlanFromStore.message) {
      setUpcomingPlan(upcomingPlanFromStore.data);
    } else {
      dispatch(getUpcomingPlan());
    }
  }, [upcomingPlanFromStore]);
  return (
    <div className="card-body p-3">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5 className="mb-0">
            Upcoming Plan <span className="text-primary"># {upcomingPlan?.planCode}</span>
          </h5>
        </div>
        {loading && !upcomingPlan && (
          <div className="text-center">
            {" "}
            <Spinner />{" "}
          </div>
        )}
        {!loading && !upcomingPlan && <p>No Upcoming Plan found</p>}

        {!!upcomingPlan && (
          <div className="text-end">
            <p className="mb-0">
              <IntlMessages id={"myPlan.planDuration"} />: <span className="text-primary">{upcomingPlan.durationType}</span>
            </p>
            <p className="mb-0">
              <IntlMessages id={"myPlan.planType"} />: <span className="text-primary">{upcomingPlan.planType}</span>
            </p>
          </div>
        )}
      </div>
      {!!upcomingPlan && (
        <div className="d-flex justify-content-between pt-3">
          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
            {/* <i className="fas fa-clipboard-list fa-3x me-lg-4 mb-3 mb-lg-0"></i> */}
            <FaUser size={30} />
            <div className="current-plan-padding-left">
              <p className="fw-bold mb-1">
                <IntlMessages id="myPlan.maxEmployee" />
              </p>
              <p className="fw-bold mb-0">{upcomingPlan.maxEmployee}</p>
            </div>
          </div>
          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
            <FaLaptopCode size={35} />
            <div className="current-plan-padding-left">
              <p className="fw-bold mb-1">
                <IntlMessages id="myPlan.maxAsset" />
              </p>
              <p className="fw-bold mb-0">{upcomingPlan.maxAsset}</p>
            </div>
          </div>
          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
            <FaProjectDiagram size={33} />
            <div className="current-plan-padding-left">
              <p className="fw-bold mb-1">
                <IntlMessages id="myPlan.maxProject" />
              </p>
              <p className="fw-bold mb-0">{upcomingPlan.maxProject}</p>
            </div>
          </div>
          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
            <FaFileAlt size={33} />
            <div className="current-plan-padding-left">
              <p className="fw-bold mb-1">
                <IntlMessages id="myPlan.maxDocument" />
              </p>
              <p className="fw-bold mb-0">{upcomingPlan.maxDocument}</p>
            </div>
          </div>
        </div>
      )}
      {/* buy or upgrade buttons */}
      <div className="d-flex justify-content-center"></div>
    </div>
  );
};

export default UpcomingSubscriptionCard;
