import { GET_CURRENTPLAN_REQUEST, GET_UPCOMINGPLAN_REQUEST } from "./type";

export const getCurrentPlan = () => {
  return {
    type: GET_CURRENTPLAN_REQUEST,
  };
};
export const getUpcomingPlan = () => {
  return {
    type: GET_UPCOMINGPLAN_REQUEST,
  };
};
