import { GET_CURRENTPLAN_ERROR, GET_CURRENTPLAN_REQUEST, GET_CURRENTPLAN_SUCCESS, GET_UPCOMINGPLAN_ERROR, GET_UPCOMINGPLAN_REQUEST, GET_UPCOMINGPLAN_SUCCESS } from "../actions/type";

const INIT_STATE = {
  currentPlan: {
    data: undefined,
    message: undefined,
  },
  upcomingPlan: {
    data: undefined,
    message: undefined,
  },
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_CURRENTPLAN_REQUEST:
      return { ...state };
    case GET_CURRENTPLAN_SUCCESS:
      return { ...state, currentPlan: { data: payload.data, message: payload.message } };
    case GET_CURRENTPLAN_ERROR:
      return { ...state, currentPlan: { data: undefined, message: payload.message } };
    case GET_UPCOMINGPLAN_REQUEST:
      return { ...state };
    case GET_UPCOMINGPLAN_SUCCESS:
      return { ...state, upcomingPlan: { data: payload.data, message: payload.message } };
    case GET_UPCOMINGPLAN_ERROR:
      return { ...state, upcomingPlan: { data: undefined, message: payload.message } };
    default:
      return { ...state };
  }
};
