import taxes1 from "../Images/taxes/taxes01.jpg";
import taxes2 from "../Images/taxes/taxes02.jpg";
import taxes3 from "../Images/taxes/taxes03.jpg";
import taxes4 from "../Images/taxes/taxes04.jpg";
import taxes5 from "../Images/taxes/taxes05.jpg";
import taxes6 from "../Images/taxes/taxes06.jpg";

export default [
  {
    id: 1,
    section_desc:
      "Teksaar Innovations Pvt Ltd. is proud to introduce the Compensation & Taxes feature in HRMSHOST Software, our cloud-based human resource management solution. This feature offers a comprehensive suite of tools and functionalities to manage employee compensation and handle various tax-related processes efficiently.",
    section_image: taxes1,
  },
  {
    id: 2,
    section_desc:
      "One of the key elements of the Compensation & Taxes feature is 'Employee CTC Creation'. HRMSHOST allows HR administrators to define and create the employee's Cost to Company (CTC) structure effortlessly. By capturing all components of the CTC, such as basic salary, allowances, bonuses, and reimbursements, the software ensures accurate and transparent compensation management.",
    section_image: taxes2,
  },
  {
    id: 3,
    section_desc:
      "HRMSHOST also provides an intuitive interface for 'Statutory Components Settings'. This feature enables HR administrators to configure and customize the statutory components of employee compensation, such as provident fund (PF), employee state insurance (ESI), and professional tax (PT).",
    section_image: taxes3,
  },
  {
    id: 4,
    section_desc:
      "Tax calculations can be complex, but HRMSHOST simplifies the process with its advanced 'Tax Calculations' feature. The software automates the calculation of income tax deductions based on the employee's salary structure, investment declarations, and applicable tax slabs.",
    section_image: taxes4,
  },
  {
    id: 5,
    section_desc:
      "In addition to tax calculations, HRMSHOST facilitates seamless management of PF, ESI, and PT calculations. The software automatically calculates the PF contribution, ESI deductions, and PT deductions based on the employee's salary and statutory requirements.",
    section_image: taxes5,
  },
  {
    id: 6,
    section_desc:
      "HRMSHOST's Compensation & Taxes feature also offers robust reporting capabilities. HR administrators can generate comprehensive reports related to compensation, tax deductions, statutory compliance, and more.",
    section_image: taxes6,
  },
];
