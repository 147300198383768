import payroll01 from "../Images/payroll/payroll01.jpg";
import payroll02 from "../Images/payroll/payroll02.jpg";
import payroll03 from "../Images/payroll/payroll03.jpg";
import payroll04 from "../Images/payroll/payroll04.jpg";
import payroll05 from "../Images/payroll/payroll05.jpg";
import payroll06 from "../Images/payroll/payroll06.jpg";

export default [
  {
    id: 1,
    section_desc:
      "Payroll management is a crucial aspect of any organization's human resource operations, and HRMSHOST Software offers an advanced and efficient solution for small and medium-sized enterprises. With its cloud-based architecture, HRMSHOST provides a range of features that streamline and simplify the payroll process.",
    section_image: payroll01,
  },
  {
    id: 2,
    section_desc:
      "One of the key advantages of HRMSHOST's Payroll Management module is its 'Single Click Payroll Creation' feature. This allows HR administrators to generate payroll with just a single click, saving valuable time and effort.",
    section_image: payroll02,
  },
  {
    id: 3,
    section_desc:
      "Another noteworthy feature is the 'Payroll Summary' function. This feature provides a comprehensive overview of the entire payroll, including details such as total salary expenses, deductions, and tax liabilities.",
    section_image: payroll03,
  },
  {
    id: 4,
    section_desc:
      "HRMSHOST's Payroll Management also offers 'Payroll Customization' options, allowing organizations to tailor the payroll process to their specific needs. From defining salary components and tax rules to configuring leave policies and reimbursement criteria, ",
    section_image: payroll04,
  },
  {
    id: 5,
    section_desc:
      "Additionally, HRMSHOST enables HR administrators to track the 'Execution and Payment Status' of each payroll cycle. This feature provides real-time updates on the progress of payroll processing, from the initial data entry to the final disbursement of salaries.",
    section_image: payroll05,
  },
  {
    id: 6,
    section_desc:
      "Lastly, HRMSHOST facilitates the generation of 'Payslips' for employees. The software automatically generates detailed and professional payslips that reflect the salary components, deductions, and tax calculations. Employees can access their payslips through the software's employee self-service portal, promoting transparency and empowering individuals to review their salary details conveniently.",
    section_image: payroll06,
  },
];
