import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAccessToken } from "../auth/HandleCookies";
import PlanDetailBox from "./PlanDetailBox";

const PlanDetail = ({ plans, durationType }) => {
  let access_token = getAccessToken();
  const [showReg, setShowReg] = useState(false);
  const currentPlanFromStore = useSelector((store) => {
    return store.companySubscriptionReducer.currentPlan.data;
  });

  return (
    <>
      <div className="row pricing-list bounce-invert">
        {plans &&
          Object.values(plans).map((data, i) => {
            return (
              data.plan && (
                <PlanDetailBox
                  currentPlan={currentPlanFromStore}
                  durationType={durationType}
                  plan={data}
                  key={i}
                />
              )
            );
          })}
      </div>
      {/* <div>
        {!access_token && (
          <a onClick={() => setShowReg(true)} className="freeTrailButtonAfterPlans" target="_blank">
            <IntlMessages id={"plan.trialNow"} />
          </a>
        )}
      </div>
      {showReg && <CompanyRegistrationWrapper setShowReg={setShowReg} />} */}
    </>
  );
};

export default PlanDetail;
