// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL(".././assets/images/background-image02.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerHeading {
  font-size: 3rem;
}
.innerPageBg {
  background-color: teal;
}
.singlePageContainer .row {
  align-items: center;
}
.pageContentWrapper {
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}
.pageContentWrapper::before {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom;
}
.pageContentSection .container .row .innerContentWrapper {
  color: #000;
  background: unset;
  padding: 30px;
  border-radius: 23% 0% 30% 0%;
  height: 100%;
  margin-bottom: 0;
  border: 10px solid teal;
}
.readMoreLink {
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: teal;
}
.pageContentSection .container .row {
  align-items: center;
}
.pageContentSection .container .row .innerPageContent {
  color: #000;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .pageContentSection .container .row .innerContentWrapper {
    padding: 30px;
    border-radius: 15% 0% 15% 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/innerPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,yDAAkE;EAClE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,4BAA4B;EAC5B,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,4BAA4B;EAC5B,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,4BAA4B;EAC9B;AACF","sourcesContent":[".bannerHeading {\n  font-size: 3rem;\n}\n.innerPageBg {\n  background-color: teal;\n}\n.singlePageContainer .row {\n  align-items: center;\n}\n.pageContentWrapper {\n  padding: 50px 0;\n  position: relative;\n  overflow: hidden;\n}\n.pageContentWrapper::before {\n  content: \"\";\n  background-image: url(\".././assets/images/background-image02.jpg\");\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: -1;\n  background-attachment: fixed;\n  background-repeat: no-repeat;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: bottom;\n}\n.pageContentSection .container .row .innerContentWrapper {\n  color: #000;\n  background: unset;\n  padding: 30px;\n  border-radius: 23% 0% 30% 0%;\n  height: 100%;\n  margin-bottom: 0;\n  border: 10px solid teal;\n}\n.readMoreLink {\n  font-size: 14px;\n  text-decoration: none;\n  display: inline-block;\n  text-align: center;\n  width: 100%;\n  color: teal;\n}\n.pageContentSection .container .row {\n  align-items: center;\n}\n.pageContentSection .container .row .innerPageContent {\n  color: #000;\n  margin-bottom: 0;\n}\n\n@media screen and (max-width: 768px) {\n  .pageContentSection .container .row .innerContentWrapper {\n    padding: 30px;\n    border-radius: 15% 0% 15% 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
