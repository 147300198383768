import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearCookies, getAccessToken } from "../components/auth/HandleCookies";

function Protected({ Component }) {
  let accessToken = getAccessToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken) {
      clearCookies();
      navigate("/");
    }
  });
  return (
    <div>
      <Component />
    </div>
  );
}

export default Protected;
