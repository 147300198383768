import React, { useState } from "react";
import { AppBar, Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  AssignmentInd as AssignmentIndIcon,
  CurrencyRupeeOutlined as CurrencyRupeeOutlinedIcon,
  FeaturedPlayListOutlined as FeaturedPlayListOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
  HomeOutlined as HomeOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  LockReset as LockResetIcon,
  LoginOutlined as LoginOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import hrmsHostLogo250by60 from "../../assets/images/hrmshost-logo-250x60.png";
import ChangePassword from "../../components/auth/ChangePassword";
import LoggedInUser from "../../components/myAccount/LoggedInUser";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { logoutApi } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import { clearCookies, getAccessToken, getProfile } from "../auth/HandleCookies";
import SignIn from "../auth/Login";
import CompanyRegistrationWrapper from "../companyRegistration/CompanyRegistrationWrapper";

const styles = {
  appBar: {
    background: "#fff",
    position: "sticky",
    height: "72px",
    top: "0",
    zIndex: "999",
    justifyContent: "space-evenly",
  },
  navlinks: {
    textAlign: "center",
  },
  logoSection: {
    flex: "3",
    cursor: "pointer",
    color: "#6c757d",
  },
  loggedIn: {
    textAlign: "center",
    flex: "6",
  },
  loggedOut: {
    textAlign: "right",
  },
  link: {
    textDecoration: "none",
    color: "#6c757d",
    fontSize: "16px",
    marginLeft: "24px",
    textTransform: "none",
    letterSpacing: "1px",
    fontWeight: "500",
    "&:hover": {
      color: "#008080",
    },
  },
  openPortalApp: {
    textAlign: "right",
  },
};

function DrawerComponent({ token }) {
  const [showLogin, setShowLogin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const onMenuSelect = (url) => {
    setAnchorEl(null);
    window.location.href = url;
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", top: "60px" }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className="myProfilePopup">
          <MenuItem onClick={() => onMenuSelect("/#homeSection")}>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.home`} />
          </MenuItem>
          <MenuItem onClick={() => onMenuSelect("/#featuresSection")}>
            <ListItemIcon>
              <FeaturedPlayListOutlinedIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.feature`} />
          </MenuItem>
          <MenuItem onClick={() => onMenuSelect("/#pricingPlanSection")}>
            <ListItemIcon>
              <CurrencyRupeeOutlinedIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.pricing`} />
          </MenuItem>
          <MenuItem onClick={() => onMenuSelect("/FAQ")}>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.faq`} />
          </MenuItem>

          {!token && (
            <>
              <MenuItem onClick={() => onMenuSelect("/#aboutWraper")}>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <IntlMessages id={`navbar.text.aboutUs`} />
              </MenuItem>
              <MenuItem onClick={() => setShowLogin(true)}>
                <ListItemIcon>
                  <LoginOutlinedIcon />
                </ListItemIcon>
                <IntlMessages id={`navbar.text.login`} />
              </MenuItem>
            </>
          )}
        </Menu>
      </Box>

      <IconButton onClick={openMenu}>
        <MenuIcon />
      </IconButton>
      {showLogin && <SignIn setShowLogin={setShowLogin} />}
    </>
  );
}

function Navbar() {
  const navigate = useNavigate();
  let accessToken = getAccessToken();
  const profile = JSON.parse(getProfile());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [openChangePassword, setChangePassword] = useState(false);

  const [showLogin, setShowLogin] = useState(false);

  const logout = () => {
    clearCookies();
    window.location.href = "/";
  };

  const handlePortalRedirect = async () => {
    const profile = JSON.parse(getProfile());
    const userName = profile?.username;
    await AxiosAdminConfig.post(`${logoutApi}?username=${encodeURIComponent(userName)}`);
    window.open(process.env.REACT_APP_PORTAL_URL);
  };

  const [show, setShow] = useState(false);
  const [showRegForm, setShowRegForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleShow = () => setShow(true);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onMyProfileClick = () => {
    setOpenDialog(true);
    handleClose();
  };

  const onChangePasswordClick = () => {
    setChangePassword(true);
    handleClose();
  };
  const onMyOrdersClick = () => navigate("/orders");

  const openRegForm = () => setShowRegForm(true);

  return (
    <>
      <AppBar
        position="sticky"
        style={styles.appBar}>
        <div className="navWrapper">
          <div style={styles.logoSection}>
            <img
              src={hrmsHostLogo250by60}
              alt="Human-resource-Management-system"
              className="company-logo ms-2"
              height={isMobile ? "40px" : "50px"}
              onClick={() => navigate("/")}
            />
          </div>
          {!isMobile && (
            <div style={{ ...styles.navlinks, ...(accessToken ? styles.loggedIn : styles.loggedOut) }}>
              <a
                href="/#homeSection"
                style={styles.link}>
                <IntlMessages id={`navbar.text.home`} />
              </a>
              <a
                href="/#featuresSection"
                style={styles.link}>
                <IntlMessages id={`navbar.text.features`} />
              </a>
              <a
                href="/#pricingPlanSection"
                style={styles.link}>
                <IntlMessages id={`navbar.text.pricing`} />
              </a>
              <Link
                to="/FAQ"
                style={styles.link}>
                <IntlMessages id={`navbar.text.faq`} />
              </Link>
              {!accessToken && (
                <a
                  href="/#aboutWraper"
                  style={styles.link}>
                  <IntlMessages id={`navbar.text.aboutUs`} />
                </a>
              )}
              {!accessToken && (
                <Link
                  to="#"
                  style={styles.link}
                  onClick={() => setShowLogin(true)}>
                  <IntlMessages id={`navbar.text.login`} />
                </Link>
              )}
            </div>
          )}
          <div>
            {!accessToken && (
              <a
                href="/#pricingPlanSection"
                className="freeTrailHeaderButton">
                <span className="freeTrailHeaderButtonText">
                  <IntlMessages id={"navbar.text.start.free.trial"} />
                </span>
              </a>
            )}
          </div>
          {accessToken && (
            <>
              {/* <div style={styles.openPortalApp}>
                <Button
                  className="openPortalAppButton"
                  onClick={handlePortalRedirect}>
                  <IntlMessages id={`navbar.text.openPortalApp`} />
                  &nbsp;
                  <LaunchIcon className="portal-link-icon" />
                </Button>
              </div> */}
              <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", top: "60px" }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}>
                    <Avatar
                      className="bgColor profileSize"
                      onClick={handleShow}>
                      {profile.firstName.substring(0, 1).toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                className="myProfilePopup">
                <MenuItem className="position">
                  <Avatar className="bgColor">{profile.firstName.substring(0, 1).toUpperCase()}</Avatar>
                </MenuItem>
                <div className="position">{profile.firstName + " " + profile.lastName}</div>
                <div className="position">{profile.email}</div>
                <Divider />
                <MenuItem onClick={onMyProfileClick}>
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.myProfile`} />
                </MenuItem>
                <MenuItem onClick={onMyOrdersClick}>
                  <ListItemIcon>
                    <ShoppingCartOutlinedIcon />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.myOrders`} />
                </MenuItem>
                <MenuItem onClick={onChangePasswordClick}>
                  <ListItemIcon>
                    <LockResetIcon />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.changePassword`} />
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.logout`} />
                </MenuItem>
              </Menu>
            </>
          )}
          {isMobile && <DrawerComponent token={accessToken} />}
        </div>
      </AppBar>
      {showRegForm && <CompanyRegistrationWrapper setShowReg={setShowRegForm} />}
      {showLogin && <SignIn setShowLogin={setShowLogin} />}
      {openDialog && (
        <LoggedInUser
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      )}
      {openChangePassword && (
        <ChangePassword
          openChangePassword={openChangePassword}
          setChangePassword={setChangePassword}
        />
      )}
    </>
  );
}

export default Navbar;
