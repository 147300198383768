import React from "react";
import loadingGif from "../../../assets/images/loader1.gif";

const Spinner = () => {
  return (
    <div className="mySpinner">
      <img
        src={loadingGif}
        alt="wait until the page loads"
      />
    </div>
  );
};

export default Spinner;
