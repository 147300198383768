import assets01 from "../Images/assets/Asset01.jpg";
import assets02 from "../Images/assets/Asset02.jpg";
import assets03 from "../Images/assets/Asset03.jpg";
import assets04 from "../Images/assets/Asset04.jpg";
import assets05 from "../Images/assets/Asset05.jpg";
import assets06 from "../Images/assets/Asset06.jpg";

export default [
  {
    id: 1,
    section_desc:
      "Teksaar Innovations Pvt Ltd. is pleased to present the Asset & Project feature in HRMSHOST Software, our cloud-based human resource management solution. This feature offers a comprehensive set of functionalities to manage assets and projects efficiently, ensuring seamless collaboration and resource allocation within organizations.",
    section_image: assets01,
  },
  {
    id: 2,
    section_desc:
      "The Asset & Project feature in HRMSHOST includes 'Asset Creation & Categories'. This functionality allows HR administrators to create and define various assets within the system. Administrators can categorize assets based on their types, such as laptops, vehicles, or office equipment.",
    section_image: assets02,
  },
  {
    id: 3,
    section_desc:
      "Asset Assignment is another vital aspect of this feature. HRMSHOST enables HR administrators to assign assets to employees or project teams seamlessly. By associating assets with specific individuals HRMSHOST effectively manage asset distribution within the organization.",
    section_image: assets03,
  },
  {
    id: 4,
    section_desc:
      "In addition to asset management, HRMSHOST facilitates 'Project Creation' This feature empowers project managers to create and define projects within the software. They can assign project names, descriptions, timelines, and milestones, enabling comprehensive project planning and tracking.",
    section_image: assets04,
  },
  {
    id: 5,
    section_desc:
      "Resource Allocation is a key component of the Asset & Project feature. HRMSHOST allows project managers to allocate resources, such as employees or teams, to specific projects. With resource allocation capabilities, organizations can ensure optimal utilization of their workforce, assign responsibilities effectively, and streamline project execution.",
    section_image: assets05,
  },
  {
    id: 6,
    section_desc:
      "HRMSHOST's Asset & Project feature also includes 'Resource Roles' This functionality enables project managers to define and assign specific roles to employees within a project. By assigning roles, such as project lead, team member, or coordinator, organizations can establish clear hierarchies and responsibilities, ensuring smooth coordination and collaboration among team members.",
    section_image: assets06,
  },
];
