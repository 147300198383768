import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { GET_CURRENTPLAN_ERROR, GET_CURRENTPLAN_REQUEST, GET_CURRENTPLAN_SUCCESS, GET_UPCOMINGPLAN_ERROR, GET_UPCOMINGPLAN_SUCCESS, GET_UPCOMINGPLAN_REQUEST } from "../actions/type";
import { getcurrentPlan, getupcomingPlan } from "../ApiLists";

const getCurrentPlan = async () => {
  return await AxiosAdminConfig.get(`${getcurrentPlan}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
const getUpcomingPlan = async () => {
  return await AxiosAdminConfig.get(`${getupcomingPlan}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* getCurrentPlanGenerator() {
  try {
    const response = yield call(getCurrentPlan);
    yield put({ type: GET_CURRENTPLAN_SUCCESS, payload: { data: response, message: "" } });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_CURRENTPLAN_SUCCESS,
        payload: { data: {}, message: "Not Found" },
      });
    } else {
      yield put({ type: GET_CURRENTPLAN_ERROR, payload: { data: {}, message: e.message } });
    }
  }
}
function* getUpcomingPlanGenerator() {
  try {
    const response = yield call(getUpcomingPlan);
    yield put({ type: GET_UPCOMINGPLAN_SUCCESS, payload: { data: response, message: "" } });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_UPCOMINGPLAN_SUCCESS,
        payload: { data: {}, message: "Not Found" },
      });
    } else {
      yield put({ type: GET_UPCOMINGPLAN_ERROR, payload: { data: {}, message: e.message } });
    }
  }
}

export default function* watcherSaga() {
  yield takeLatest(GET_CURRENTPLAN_REQUEST, getCurrentPlanGenerator);
  yield takeLatest(GET_UPCOMINGPLAN_REQUEST, getUpcomingPlanGenerator);
}
