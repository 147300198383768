import React, { useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Link } from "react-router-dom";
import SignIn from "../auth/Login";
const Step4 = () => {
  const [showLogin, setShowLogin] = useState(false);
  return (
    <div className="login-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h5>Congratulations!! Your Company and User Account is created successfully. </h5>{" "}
            <div className="freeTrailHeaderButton text-center mx-auto">
              <Link
                to="#"
                className="loginText"
                onClick={() => setShowLogin(true)}>
                <IntlMessages id={"form.login.now"} />
              </Link>
            </div>
          </div>
        </div>
      </div>{" "}
      {showLogin && <SignIn setShowLogin={setShowLogin} />}
    </div>
  );
};
export default Step4;
