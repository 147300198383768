import Axios from "axios";
import { clearCookies, getAccessToken, getCompanyCode } from "../components/auth/HandleCookies";

const AxiosAdminConfig = Axios.create({
  baseURL: process.env.REACT_APP_ERMS_ADMIN_API,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosAdminConfig.interceptors.request.use(
  function (config) {
    let accessToken = getAccessToken();
    config.headers["accept"] = "application/json";
    config.headers["X-COMPANY-CODE"] = getCompanyCode();
    config.headers["Authorization"] = "Bearer " + accessToken;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosAdminConfig.interceptors.response.use(
  function (response) {
    return response.data.data;
  },
  function (error) {
    let errorResponse = {
      message: "Error",
      errorCode: error?.response?.status,
    };
    if (error?.response?.status === 500) {
      errorResponse.message = "Internal Server Error";
    } else if (error?.response?.status === 401 || error?.response?.status === 402 || error?.response?.status === 403) {
      setTimeout(() => {
        clearCookies();
        sessionStorage.clear();
        location.reload();
        window.location.replace("/");
      }, 3000);
      errorResponse.message = "Session Expired Please Login";
    } else if (error?.response?.status === 400) {
      let errorResponseObject = JSON.parse(error?.response?.data);

      errorResponse.message = errorResponseObject.message;
    }
    return Promise.reject(errorResponse);
  }
);

const CancelToken = Axios.CancelToken;

const AxiosInstanceForBlob = Axios.create({
  baseURL: process.env.REACT_APP_ERMS_ADMIN_API,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosInstanceForBlob.interceptors.request.use(
  function (config) {
    let token = getAccessToken();
    config.method = "GET";
    config.responseType = "blob";
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstanceForBlob.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    let errorResponse = {
      message: "Error!",
      errorCode: error?.response?.status,
    };
    if (error?.response?.status === 500) {
      errorResponse.message = "Internal Server Error";
    } else if (error?.response?.status === 401 || error?.response?.status === 402 || error?.response?.status === 403) {
      setTimeout(() => {
        window.location.replace("/login");
        history.push("/login");
      }, 3000);
      clearCookies();
      errorResponse.message = "Session Expired Please Login";
    } else if (error?.response?.status === 400) {
      let msg = await error?.response?.data?.text().then((text) => text);
      let msgJson = JSON.parse(msg);
      errorResponse.message = msgJson.message;
    }
    return Promise.reject(errorResponse);
  }
);

export { AxiosAdminConfig, CancelToken, AxiosInstanceForBlob };
