import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import React from "react";
import { Col, Row, Table } from "reactstrap";
import defaultImg from "../../assets/images/EmpImage.png";
import IntlMessages from "../../util/IntlMessages";
import { getProfile } from "../auth/HandleCookies";
import ModelSel from "../shared/Model/Modelsel";

const LoggedInUser = ({ openDialog, setOpenDialog }) => {
  const myProfile = JSON.parse(getProfile());
  const ROLES = [
    {
      label: "Portal Admin",
      role: "portal_admin",
    },
    {
      label: "Portal User",
      role: "portal_user",
    },
    {
      label: "Employee",
      role: "employee",
    },
  ];

  let roles = myProfile.roles.map((role) => {
    let roleObj = ROLES.find((obj) => obj.role == role);
    return roleObj.label;
  });

  return (
    <>
      <ModelSel
        open={openDialog}
        heading={<IntlMessages id="title.myProfile.text" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="medium"
              onClick={() => setOpenDialog(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="md"
        className="largePopUp">
        <Row className="profileImage">
          <Col className="myProfile">
            <img
              src={defaultImg}
              alt=""
              width={70}
              height={80}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table className="myProfileDataTable">
              <tbody>
                <tr>
                  <td className="tableColon">User Name</td>
                  <td>:</td>
                  <td>{myProfile.username}</td>
                </tr>
                <tr>
                  <td className="tableColon">First Name</td>
                  <td>:</td>
                  <td>{myProfile.firstName}</td>
                </tr>
                <tr>
                  <td className="tableColon">Last Name</td>
                  <td>:</td>
                  <td>{myProfile.lastName}</td>
                </tr>
                <tr>
                  <td className="tableColon">Email</td>
                  <td>:</td>
                  <td>{myProfile.email}</td>
                </tr>
                <tr>
                  <td className="tableColon">User Role</td>
                  <td>:</td>
                  <td>{roles.join(", ")}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModelSel>
    </>
  );
};

export default LoggedInUser;
