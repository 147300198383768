import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import * as yup from "yup";
import IntlMessages from "../../util/IntlMessages";
import Spinner from "../shared/spinner/Spinner";

const Step3 = ({ company, setCompanyDetail, loading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [stateList, setStateList] = useState([]);

  let validationSchema = yup.object().shape({
    firstName: yup.string().trim().required("*Required"),
    city: yup.string().trim().required("*Required"),
    state: yup.string().trim().required("*Required"),
    lastName: yup.string().trim().required("*Required"),
    username: yup.string().trim().required("*Required"),
    companyMobile: yup.string().trim().required("*Required"),
    password: yup
      .string()
      .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, "Password atleast have 8 character containing one special character, Uppercase, Lowercase and Digit")
      .required("*Required"),
    confirmPassword: yup
      .string()
      .trim()
      .required("*Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      username: "",
      city: "",
      state: "",
      companyMobile: "",
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  useEffect(() => {
    getAllState();
  }, []);

  useEffect(() => {
    if (company) {
      formik.setFieldValue("username", company?.companyEmail);
    }
  }, [company]);

  const handleSubmit = async (values, resetForm) => {
    setCompanyDetail((prev) => ({ ...prev, ...values }));
  };

  const getAllState = async () => {
    Axios.get(`${process.env.REACT_APP_ERMS_ADMIN_API}public/all-state`)
      .then((res) => {
        setStateList(res.data.data);
      })
      .catch((err) => {
        let errorResponse = JSON.parse(err.request.response);
        toast.error(errorResponse.message);
      });
  };

  return (
    <>
      <div className="login-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-section companyRegistration">
                <>
                  <div className="formTittle">
                    <h3>
                      <IntlMessages id={`heading.company.userDetail`} />
                    </h3>
                  </div>
                  <div className="signup-inner-form">
                    <Form
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onSubmit={formik.handleSubmit}
                      className="mdForm">
                      <Row>
                        <Col>
                          <FormGroup>
                            <TextField
                              disabled
                              error={formik.touched["username"] && formik.errors["username"]}
                              label={<IntlMessages id={`username.text`} />}
                              id="username"
                              name="username"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            {renderErrorMessage("username")}
                          </FormGroup>
                        </Col>
                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup>
                            <TextField
                              error={formik.touched["companyMobile"] && formik.errors["companyMobile"]}
                              label={<IntlMessages id={`companyMobile.text`} />}
                              id="companyMobile"
                              type="number"
                              name="companyMobile"
                              value={formik.values.companyMobile}
                              onChange={formik.handleChange}
                            />
                            {renderErrorMessage("companyMobile")}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup className="eyeIconField">
                            <TextField
                              error={formik.touched["password"] && formik.errors["password"]}
                              label={<IntlMessages id={`password.text`} />}
                              id="password"
                              name="password"
                              value={formik.values.password.trim()}
                              onChange={formik.handleChange}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: showPassword ? <VisibilityOff onClick={() => setShowPassword(false)} /> : <Visibility onClick={() => setShowPassword(true)} />,
                              }}
                            />
                            {renderErrorMessage("password")}
                          </FormGroup>
                        </Col>
                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup className="eyeIconField">
                            <TextField
                              error={formik.touched["confirmPassword"] && formik.errors["confirmPassword"]}
                              label={<IntlMessages id={`confirmPassword.text`} />}
                              id="confirmPassword"
                              name="confirmPassword"
                              value={formik.values.confirmPassword.trim()}
                              onChange={formik.handleChange}
                              type={showConfirmPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: showConfirmPassword ? <VisibilityOff onClick={() => setShowConfirmPassword(false)} /> : <Visibility onClick={() => setShowConfirmPassword(true)} />,
                              }}
                            />
                            {renderErrorMessage("confirmPassword")}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup>
                            <TextField
                              error={formik.touched["firstName"] && formik.errors["firstName"]}
                              label={<IntlMessages id={`firstName.text`} />}
                              id="firstName"
                              name="firstName"
                              value={formik.values.firstName.trim()}
                              onChange={formik.handleChange}
                            />
                            {renderErrorMessage("firstName")}
                          </FormGroup>
                        </Col>
                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup>
                            <TextField
                              error={formik.touched["lastName"] && formik.errors["lastName"]}
                              label={<IntlMessages id={`lastName.text`} />}
                              id="lastName"
                              name="lastName"
                              value={formik.values.lastName.trim()}
                              onChange={formik.handleChange}
                            />
                            {renderErrorMessage("lastName")}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup>
                            <TextField
                              error={formik.touched["city"] && formik.errors["city"]}
                              label={<IntlMessages id={`city.text`} />}
                              id="city"
                              name="city"
                              value={formik.values.city.trim()}
                              onChange={formik.handleChange}
                            />
                            {renderErrorMessage("city")}
                          </FormGroup>
                        </Col>

                        <Col
                          md={6}
                          xs={12}>
                          <FormGroup>
                            <Input
                              type="select"
                              name="state"
                              value={formik.values.state}
                              onChange={formik.handleChange}
                              className="state-dropdown"
                              invalid={formik.touched.state && !formik.values.state}
                              onBlur={formik.handleBlur}>
                              <option value="">-- Select State --</option>
                              {stateList &&
                                Object.values(stateList).map((value) => {
                                  return (
                                    <option
                                      key=""
                                      value={value.stateCode}>
                                      {value.stateName}
                                    </option>
                                  );
                                })}
                            </Input>
                            {renderErrorMessage("state")}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {loading && (
                          <Col>
                            <div className="text-center">
                              <Spinner />
                            </div>
                          </Col>
                        )}
                        <Col
                          xl={12}
                          md={12}
                          xs={12}
                          className="text-center next-finish-btn">
                          <button
                            className={loading ? "button-disable" : "loginButton"}
                            type="submit"
                            disabled={loading ? true : false}>
                            <span>Finish</span>
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
