import React from "react";
import { Container } from "reactstrap";
import "../innerPage.css";
import { Fade } from "react-awesome-reveal";

function Imagesection({ Image, fadeFrom }) {
  return (
    <div className="col-lg-6 mb-5">
      <Fade
        right={fadeFrom === "right"}
        left={fadeFrom === "left"}>
        <div className="aboutUsIMage">
          <img
            className="aboutImage"
            alt="We provide Human Resource Management Software"
            data-wow-delay="0.3s"
            src={Image}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </Fade>
    </div>
  );
}

function Contentsection({ Desc, fadeFrom }) {
  return (
    <div className="col-lg-6 mb-5">
      <Fade
        right={fadeFrom === "right"}
        left={fadeFrom === "left"}>
        <div className="innerContentWrapper">
          <p className="innerPageContent">{Desc}</p>
        </div>
      </Fade>
    </div>
  );
}

const SectionContent = ({ id, sectionImage, sectionDesc }) => {
  return (
    <section className="pageContentSection">
      <Container>
        <div className="row">
          {id % 2 === 0 ? (
            <>
              <Imagesection
                Image={sectionImage}
                fadeFrom="left"
              />
              <Contentsection
                Desc={sectionDesc}
                fadeFrom="right"
              />
            </>
          ) : (
            <>
              <Contentsection
                Desc={sectionDesc}
                fadeFrom="left"
              />
              <Imagesection
                Image={sectionImage}
                fadeFrom="right"
              />
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default SectionContent;
