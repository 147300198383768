import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import { React, useEffect, useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import Spinner from "../shared/spinner/Spinner";
import { AxiosInstanceForBlob } from "../../config/AxiosAdminConfig";
import { toast } from "react-toastify";

const Invoice = ({ openDialog, setOpenDialog, orderView }) => {
  const [pdfUrl, setPDFUrl] = useState();
  const generatePDF = async () => {
    await AxiosInstanceForBlob.get(`er/invoice/${orderView.orderNumber}`)
      .then((response) => {
        const fileType = response.headers["content-disposition"];
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        setPDFUrl(fileURL);
      })
      .catch((error) => {
        setOpenDialog(false);
        toast.error(error.message);
      });
  };
  useEffect(() => {
    generatePDF();
  }, []);

  return (
    <ModelSelfFooter
      fullWidth={true}
      open={openDialog}
      heading={<IntlMessages id="form.text.plan.detail" />}
      closePopUp={
        <Tooltip
          title="Close"
          placement="top">
          <CloseIcon
            fontSize="large"
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        </Tooltip>
      }
      closeFn={() => setOpenDialog(false)}
      maxWidth="xs">
      {!!pdfUrl ? (
        <iframe
          src={`${pdfUrl}`}
          width="100%"
          height="500px"
          allowFullScreen=""
          frameBorder="0"></iframe>
      ) : (
        <Spinner />
      )}
    </ModelSelfFooter>
  );
};
export default Invoice;
