import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import "./App.css";
import Maintenance from "./Maintenance";
import WrapperComponent from "./components/WrapperComponent";
import AppLocale from "./lang";
import store from "./redux/store";

function App({ locale }) {
  const currentAppLocale = AppLocale["en"];
  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;
  if (isUnderMaintenance == "true") {
    return <Maintenance />;
  }
  return (
    <>
      <Provider store={store}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <HelmetProvider>
            <WrapperComponent />
          </HelmetProvider>
        </IntlProvider>
      </Provider>
    </>
  );
}

export default App;
