import React, { useEffect, useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import Spinner from "../shared/spinner/Spinner";
import { dateFormatter } from "../../util/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPlan } from "../../redux/actions";
import CreateOrderPopup from "../myOrder/CreateOrderPopup";
import UpcomingSubscriptionCard from "./UpcomingSubscriptionCard";
import { toast } from "react-toastify";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { getProfile } from "../auth/HandleCookies";
import { logoutApi } from "../../redux/ApiLists";
import CircularProgressBar from "../shared/progressBar/CircularProgressBar";
import { Col, Row } from "reactstrap";

const SubscriptionPlan = () => {
  const [currentPlan, setCurrentPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("currentPlan");

  const dispatch = useDispatch();
  const currentPlanFromStore = useSelector((store) => {
    return store.companySubscriptionReducer.currentPlan;
  });
  useEffect(() => {
    if (!currentPlanFromStore.data && currentPlanFromStore.message) {
      toast.error(currentPlanFromStore.message);
    } else if (currentPlanFromStore.data && Object.keys(currentPlanFromStore.data).length > 0 && !currentPlanFromStore.message) {
      setCurrentPlan(currentPlanFromStore.data);
    } else {
      dispatch(getCurrentPlan());
    }
  }, [currentPlanFromStore]);

  const onClickByNow = async () => {
    console.log("on click by now");
    setOpenInvoice(true);
  };

  const onClickUpgradeNow = () => {
    window.location.href = "/#pricingPlanSection";
  };

  useEffect(() => {
    if (currentPlan) {
      calculateProgressPerecentage();
    }
  }, [currentPlan]);

  const calculateProgressPerecentage = () => {
    let date1 = new Date(currentPlan?.planStartDate);
    let date2 = new Date(currentPlan?.planEndDate);
    let date3 = new Date();

    if (date2 <= date3) {
      setProgressPercentage(100);
      return;
    }
    // To calculate the time difference of two dates
    let Difference_In_Time_1 = date2.getTime() - date1.getTime();
    let Difference_In_Time_2 = date3.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    let totalDays = Difference_In_Time_1 / (1000 * 3600 * 24);
    let utilizedDays = Difference_In_Time_2 / (1000 * 3600 * 24);
    let progress = parseInt((utilizedDays * 100) / totalDays);
    setProgressPercentage(progress);
  };

  const handlePlanChange = (selectedPlanDuration) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSelectedPlan(selectedPlanDuration);
    }, 1000);
  };
  const isShowBuyNowButton = () => {
    return !!(currentPlan?.active && (currentPlan?.flagTrial || !currentPlan?.nextSubscriptionId));
  };
  const handlePortalRedirect = async () => {
    const profile = JSON.parse(getProfile());
    const userName = profile?.username;
    await AxiosAdminConfig.post(`${logoutApi}?username=${encodeURIComponent(userName)}`).then((response) => {});
    window.open(process.env.REACT_APP_PORTAL_URL);
  };

  return (
    <>
      <section
        id="currentSubscription"
        className="currentSubscription"
        style={{ backgroundColor: "#ebe6e3" }}>
        <div
          className="container py-5 h-100 current-plan-card-width"
          id="homeSection">
          {currentPlan?.nextSubscriptionId && (
            <div className="pricing-switcher">
              <p className="fieldset">
                <input
                  type="radio"
                  name="subscription-plan"
                  value="currentPlan"
                  id="currentPlan"
                  onChange={(e) => handlePlanChange(e.target.value)}
                />
                <label htmlFor="currentPlan">Current Plan</label>
                <input
                  type="radio"
                  name="subscription-plan"
                  value="upcomingPlan"
                  id="upcomingPlan"
                  onChange={(e) => handlePlanChange(e.target.value)}
                />
                <label htmlFor="upcomingPlan">Next Plan</label>
                <span className="switch"></span>
              </p>
            </div>
          )}

          {currentPlan?.message ? (
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12">
                <div
                  className="card card-stepper text-black"
                  style={{ borderRadius: "16px" }}>
                  <div className="card-body p-3">{currentPlan.message}</div>
                  <div className="current-plan-padding-left text-center">
                    {/* <a
                      onClick={() => onClickUpgradeNow()}
                      className="buyNowButton"
                      target="_blank">
                      <IntlMessages id={"plan.renewPlan"} />
                    </a> */}
                    <button
                      onClick={() => onClickUpgradeNow()}
                      className="buyNowButton">
                      <IntlMessages id={"plan.renewPlan"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12">
                <div
                  className="card card-stepper text-black"
                  style={{ borderRadius: "16px" }}>
                  <div className="card-body p-3 text-center">
                    <h5>{currentPlan?.companyName}</h5>

                    {currentPlan?.flagTrial && <div style={{ color: "crimson" }}>Your Trial Plan ends on {dateFormatter(currentPlan?.planEndDate)}</div>}
                    {!currentPlan?.flagTrial && <div style={{ color: "crimson" }}>Your Current Plan ends on {dateFormatter(currentPlan?.planEndDate)}</div>}
                  </div>
                  {loading ? (
                    <div className="text-center">
                      {" "}
                      <Spinner />{" "}
                    </div>
                  ) : selectedPlan == "upcomingPlan" ? (
                    <UpcomingSubscriptionCard />
                  ) : (
                    <div className="card-body p-3">
                      {!!currentPlan && (
                        <Row>
                          <Col className="col-12 col-md-4 mb-3">
                            <Row>
                              <Col>
                                <IntlMessages id={"myPlan.title"} />
                              </Col>
                              <Col>
                                <span className="text-primary"># {currentPlan?.planCode}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <IntlMessages id="Plan Start Date" />
                              </Col>
                              <Col>
                                <span className="text-primary">{dateFormatter(currentPlan.planStartDate)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <IntlMessages id="Plan End Date" />
                              </Col>
                              <Col>
                                <span className="text-primary">{dateFormatter(currentPlan.planEndDate)}</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-12 col-md-4 text-center mb-3">
                            <CircularProgressBar
                              progress={progressPercentage} // progress in percentage
                              size={100} // size of the SVG element
                              strokeWidth={10} // thickness of the circular line
                              color={"#66b2b2"}
                            />
                          </Col>
                          <Col className="col-12 col-md-4 mb-3">
                            <Row>
                              <Col>
                                <IntlMessages id={"myPlan.planDuration"} />
                              </Col>
                              <Col>
                                <span className="text-primary">{currentPlan.durationType}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <IntlMessages id="myPlan.planType" />
                              </Col>
                              <Col>
                                <span className="text-primary">{currentPlan.planType}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}

                      {/* <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h5 className="mb-0">
                            <IntlMessages id={"myPlan.title"} /> <span className="text-primary"># {currentPlan?.planCode}</span>
                          </h5>
                        </div>
                        {!loading && !currentPlan && <p>No Current Plan found</p>}

                        {!!currentPlan && (
                          <div className="text-end">
                            <p className="mb-0">
                              <IntlMessages id={"myPlan.planDuration"} />: <span className="text-primary">{currentPlan.durationType}</span>
                            </p>
                            <p className="mb-0">
                              <IntlMessages id={"myPlan.planType"} />: <span className="text-primary">{currentPlan.planType}</span>
                            </p>
                          </div>
                        )}
                      </div>

                      <div>
                        <div className="d-flex justify-content-between px-3 py-4">
                          <h5 className="mb-0">
                            <IntlMessages id="Plan Start Date" /> <span className="text-primary">{dateFormatter(currentPlan.planStartDate)}</span>
                          </h5>

                          <ProgressBar
                            bgColor={"#66b2b2"}
                            progress={progressPercentage}
                            height={20}
                          />
                          <span className="current-plan-date-style">{dateFormatter(currentPlan.planEndDate)}</span>
                        </div>
                      </div> */}

                      {/* !!currentPlan && (
                        <div className="d-flex justify-content-between">
                          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
                            <FaUser size={30} />
                            <div className="current-plan-padding-left">
                              <p className="fw-bold mb-1">
                                <IntlMessages id="myPlan.maxEmployee" />
                              </p>
                              <p className="fw-bold mb-0">{currentPlan.maxEmployee}</p>
                            </div>
                          </div>
                          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
                            <FaLaptopCode size={35} />
                            <div className="current-plan-padding-left">
                              <p className="fw-bold mb-1">
                                <IntlMessages id="myPlan.maxAsset" />
                              </p>
                              <p className="fw-bold mb-0">{currentPlan.maxAsset}</p>
                            </div>
                          </div>
                          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
                            <FaProjectDiagram size={33} />
                            <div className="current-plan-padding-left">
                              <p className="fw-bold mb-1">
                                <IntlMessages id="myPlan.maxProject" />
                              </p>
                              <p className="fw-bold mb-0">{currentPlan.maxProject}</p>
                            </div>
                          </div>
                          <div className="d-lg-flex align-items-center subscriptionPlan-card-icon">
                            <FaFileAlt size={33} />
                            <div className="current-plan-padding-left">
                              <p className="fw-bold mb-1">
                                <IntlMessages id="myPlan.maxDocument" />
                              </p>
                              <p className="fw-bold mb-0">{currentPlan.maxDocument}</p>
                            </div>
                          </div>
                        </div>
                      )*/}
                      {/* buy or upgrade buttons */}
                      <div className="portal-link">
                        <button
                          onClick={handlePortalRedirect}
                          type="button"
                          className="openPortalAppButton btn btn-secondary">
                          <span>Go to Employee Management Portal</span>
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="d-lg-flex align-items-center pt-3">
                          <div className="current-plan-padding-left text-center">
                            <a
                              onClick={() => onClickUpgradeNow()}
                              className="fw-bold"
                              style={{ cursor: "pointer" }}
                              target="_blank">
                              {/* <IntlMessages id={"plan.buyThisNow"} /> */}
                              Check out more plans below..
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {openInvoice && (
        <CreateOrderPopup
          planId={currentPlan.planId}
          openDialog={openInvoice}
          setOpenDialog={setOpenInvoice}
          showPayButton={true}
        />
      )}
    </>
  );
};

export default SubscriptionPlan;
