import React from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import SectionContent from "./reusebleContent/SectionContent";
import AssetsData from "./sectionData/assetsData";

const AssetProject = () => {
  console.log("AssetsData", AssetsData);
  return (
    <>
      <InnerPageBanner title="Assets Management" />
      <div className="pageContentWrapper">
      {AssetsData.map((item) => {
        return (
          <SectionContent
            key={item.id}
            id={item.id}
            sectionImage={item.section_image}
            sectionDesc={item.section_desc}
          />
        );
      })}
    </div>
    </>
  );
};

export default AssetProject;
