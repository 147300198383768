import document1 from "../Images/document/document1.jpg";
import document2 from "../Images/document/document2.jpg";
import document3 from "../Images/document/document3.jpg";
import document4 from "../Images/document/document4.jpg";
import document5 from "../Images/document/document5.jpg";

export default [
  {
    id: 1,
    section_desc:
      "Teksaar Innovations Pvt Ltd. proudly presents the Document Management feature in HRMSHOST Software, our cloud-based human resource management solution. This feature offers a comprehensive set of tools and functionalities to streamline document management processes within organizations, ensuring easy access, organization, and security of company and employee documents.",
    section_image: document1,
  },
  {
    id: 2,
    section_desc:
      "The Document Management feature in HRMSHOST enables organizations to manage a wide range of documents, including 'Company and Employee Documents'. From company policies and procedures to employee contracts and certifications, HR administrators can securely store and organize these important documents within the software.",
    section_image: document2,
  },
  {
    id: 3,
    section_desc:
      "To enhance document organization, HRMSHOST offers 'Custom Document Categories'. Administrators can create custom categories aligning with their organization's document taxonomy. This feature ensures that documents are appropriately classified and easily searchable, promoting efficient document management and retrieval.",
    section_image: document3,
  },
  {
    id: 4,
    section_desc:
      "HRMSHOST emphasizes document security and accessibility. The Document Management feature includes a 'Document Accessibility' feature, allowing administrators to define access permissions for different documents. By setting user-based access controls, organizations can ensure that sensitive documents are accessible only to authorized personnel, maintaining confidentiality and data security.",
    section_image: document4,
  },
  {
    id: 5,
    section_desc:
      "Document upload and download functionality is seamlessly integrated into HRMSHOST. HR administrators can easily upload new documents to the system, while employees can access and download relevant documents through their personalized employee portals.",
    section_image: document5,
  },
  {
    id: 6,
    section_desc:
      "To provide quick access to frequently used documents, HRMSHOST offers a 'Document Quick Access' feature. This feature allows users to mark specific documents as favorites or frequently accessed, making them readily available with just a few clicks.",
    section_image: document1,
  },
];
