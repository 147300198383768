import React from "react";
import { currencyConverterForPaise, dateFormatter } from "../../util/Helper";
import { useState } from "react";
import { Button } from "reactstrap";
import IntlMessages from "../../util/IntlMessages";
import Invoice from "./Invoice";

const OrderDetailCard = ({ order }) => {
  const [openInvoice, setOpenInvoice] = useState(false);

  return (
    <div className="container py-3 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-8 col-xl-6">
          <div
            className="card order-card-border-top order-card-border-bottom border-3 shadow-lg"
            style={{ "border-color": "#008080 !important" }}>
            <div className="card-body">
              <p
                className="lead fw-bold"
                style={{ color: " #008080" }}>
                <IntlMessages id={"order.orderTitle"} />
                <Button
                  className="float-end openPortalAppButton"
                  size="sm"
                  type="button"
                  color="primary"
                  onClick={() => setOpenInvoice(true)}>
                  View Invoice
                </Button>
              </p>
              <div className="row">
                <div className="col">
                  <p className="small text-muted mb-1">
                    <IntlMessages id={"order.orderDate"} />
                  </p>
                  <p>{dateFormatter(order.orderDate)}</p>
                </div>
                <div className="col">
                  <p className="small text-muted mb-1">
                    <IntlMessages id={"order.orderStatus"} />
                  </p>
                  <p>{order.status}</p>
                </div>
                <div className="col">
                  <p className="small text-muted mb-1">
                    <IntlMessages id={"order.invoiceNumber"} />
                  </p>
                  <p>#{order.orderNumber}</p>
                </div>
              </div>

              <div
                className="mx-n5 px-3 py-3"
                style={{ backgroundColor: "#f2f2f2", borderRadius: "10px" }}>
                <div className="row">
                  <div className="col-md-8 col-lg-9">
                    <p className="mb-0">{order?.planName ? order?.planName : "Dummy Plan Name"}</p>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <p className="mb-0">{currencyConverterForPaise(order.planPrice)}</p>
                  </div>
                </div>
                {!!order.discountAmount && (
                  <div className="row">
                    <div className="col-md-8 col-lg-9">
                      <p className="mb-0">
                        <IntlMessages id={"order.discount"} />
                        <span className="order-percentage">({order.discountPercent}%)</span>
                      </p>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <p
                        className="mb-0"
                        style={{ marginLeft: "-9px" }}>
                        -{currencyConverterForPaise(order.discountAmount)}
                      </p>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-8 col-lg-9">
                    <p className="mb-0">
                      <IntlMessages id={"order.tax"} /> <span className="order-percentage">({order.cgstRate + order.sgstRate}%)</span>
                    </p>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <p className="mb-0">{currencyConverterForPaise(order.cgstAmount + order.sgstAmount)}</p>
                  </div>
                </div>
              </div>

              <div className="row my-4">
                <div className="col-md-4">
                  <p
                    className="lead mb-0"
                    style={{ color: "#00000" }}>
                    <IntlMessages id={"order.netAmount"} />
                  </p>
                </div>
                <div className="col-md-4 offset-md-4 col-lg-3 offset-lg-5">
                  <p
                    className="lead fw-bold mb-0"
                    style={{ color: "#008080" }}>
                    {currencyConverterForPaise(order.totalAmount)}
                  </p>
                </div>
              </div>
              {/* <p className="mb-0">Want any help? <a href="#!" style={{"color": "#008080"}}>Please contact us</a></p> */}
            </div>
          </div>
        </div>
      </div>
      {openInvoice && (
        <Invoice
          orderView={order}
          openDialog={openInvoice}
          setOpenDialog={setOpenInvoice}
        />
      )}
    </div>
  );
};

export default OrderDetailCard;
