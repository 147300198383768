import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import React from "react";
import { FaBlog, FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { BsYoutube, BsTwitterX } from "react-icons/bs";
import { HiOutlineMailOpen } from "react-icons/hi";
import { ImWhatsapp } from "react-icons/im";
import { MdOutlineContentCopy, MdOutlinePrivacyTip } from "react-icons/md";
import { RiCustomerService2Line, RiRefund2Fill } from "react-icons/ri";

const FOOTER_DATA = {
  Contact: [
    {
      menuName: "support@hrmshost.com",
      menuIcon: <HiOutlineMailOpen />,
      menuLink: "mailto:support@hrmshost.com",
    },
    {
      menuName: "+91 93030 98756",
      menuIcon: <FaPhoneAlt />,
      menuLink: "tel:+91 93030 98756",
    },
  ],
  contactSocial: [
    {
      menuName: "",
      menuIcon: <ImWhatsapp />,
      menuLink: "https://wa.me/919303098756",
      menuClass: "whatsApp",
    },
    {
      menuName: "",
      menuIcon: <FaLinkedinIn />,
      menuLink: "https://www.linkedin.com/company/hrms-host/",
      menuClass: "linkedIn",
    },
    {
      menuName: "",
      menuIcon: <BsYoutube />,
      menuLink: "https://www.youtube.com/@hrmshost",
      menuClass: "youtube",
    },
    {
      menuName: "",
      menuIcon: <BsTwitterX />,
      menuLink: "https://twitter.com/hrmshost",
      menuClass: "twitter",
    },
  ],
  Help: [
    {
      menuName: "Terms of Service",
      menuIcon: <MdOutlineContentCopy />,
      menuLink: "/terms-of-service",
    },
    {
      menuName: "Privacy Policy",
      menuIcon: <MdOutlinePrivacyTip />,
      menuLink: "/privacy-policy",
    },
    {
      menuName: "Customer Support",
      menuIcon: <RiCustomerService2Line />,
      menuLink: "/support",
    },
    {
      menuName: "Cancellation & Refund",
      menuIcon: <RiRefund2Fill />,
      menuLink: "/cancellation",
    },
  ],
  Feature: [
    {
      menuName: "Employee Management",
      menuLink: "/employee-management",
    },
    {
      menuName: "Payroll Management",
      menuLink: "/payroll-management",
    },
    {
      menuName: "Leave Management",
      menuLink: "/leave-attendance-management",
    },
    {
      menuName: "Compensation & Taxes",
      menuLink: "/compensation-taxes",
    },
    {
      menuName: "Asset & Project",
      menuLink: "/asset-project",
    },
    {
      menuName: "Document Management",
      menuLink: "/document-management",
    },
  ],
  About: [
    {
      menuName: "About Us",
      menuIcon: <InfoOutlinedIcon fontSize="inherit" />,
      menuLink: "/#aboutWraper",
    },
    {
      menuName: "Features",
      menuIcon: <FeaturedPlayListOutlinedIcon fontSize="inherit" />,
      menuLink: "/#featuresSection",
    },
    {
      menuName: "Pricing",
      menuIcon: <CurrencyRupeeOutlinedIcon fontSize="inherit" />,
      menuLink: "/#pricingPlanSection",
    },
    {
      menuName: "FAQ",
      menuIcon: <LiveHelpOutlinedIcon fontSize="inherit" />,
      menuLink: "/FAQ",
    },
    {
      menuName: "Blogs",
      menuIcon: <FaBlog fontSize="small" />,
      menuLink: "https://teksaarinnovations.blogspot.com/",
    },
  ],
};

export default FOOTER_DATA;
