import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PREFIX = "PasswordField";
const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  iconButton: `${PREFIX}-iconButton`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {},
  [`& .${classes.input}`]: {},
  [`& .${classes.iconButton}`]: {},
  [`& .${classes.icon}`]: {},
}));

const PasswordField = ({ buttonDisabled = false, visible: visibleProp = false, ...other }) => {
  const [visible, setVisible] = useState(visibleProp);

  useEffect(() => {
    setVisible(visibleProp);
  }, [visibleProp]);

  const toggleVisibility = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const handleButtonMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <Root>
      <Input
        {...other}
        className={other.className + classes.root}
        classes={{ root: classes.root, input: classes.input }}
        type={visible ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className={classes.iconButton}
              onClick={toggleVisibility}
              onMouseDown={handleButtonMouseDown}
              disabled={other.disabled || buttonDisabled}>
              {visible ? <Visibility className={classes.icon + " " + other.classNameForIcon} /> : <VisibilityOff className={classes.icon + " " + other.classNameForIcon} />}
            </IconButton>
          </InputAdornment>
        }
      />
    </Root>
  );
};

PasswordField.propTypes = {
  ...Input.propTypes,
  buttonDisabled: PropTypes.bool,
  visible: PropTypes.bool,
};

export default PasswordField;
