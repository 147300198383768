import React from "react";

const InnerPageBanner = (props) => {
  return (
    <section className="bannerSection">
      <div className="container-fluid py-3 bg-header innerPageBg">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-4 text-white animated zoomIn bannerHeading">{props.title}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerPageBanner;
