import React, { useState } from "react";
import MyOrders from "./MyOrders";

const MyOrderWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <MyOrders />
    </div>
  );
};

export default MyOrderWrapper;
