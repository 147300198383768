import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/**
 *
 * @param {React.ElementType} children required
 * @param {string} heading modal heading
 * @param {React.ElementType} footer modal footer controls
 * @param {Function} closeFn modal required toogle
 * @param {Boolean} open required
 *
 */
const popUpTitleStyle = {
  borderBottom: "1px solid #e1e1e1",
  background: "#fafafa",
};
export default function RctModel(props) {
  const { children, footer, open, subHeading, closePopUp, fullScreen = false, fullWidth = false, heading } = props;

  const theme = useTheme();
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      open={open}
      // onClose={closePopUp}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle style={popUpTitleStyle}>
        {heading}
        {closePopUp}
        {subHeading}
      </DialogTitle>
      {/* <DialogTitle>{subHeading}</DialogTitle> */}

      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions className="footerAction">{footer}</DialogActions>}
    </Dialog>
  );
}
